<template>
    <v-col class="pa-0">
        <v-app-bar dense flat app color="white" elevation="0" :height="$vuetify.breakpoint.smAndDown ? 50 : 60">
            <router-link :to="{name: 'products'}">
                <v-img :max-width="$vuetify.breakpoint.smAndDown ? 120 : 160" contain src="/static/logo-long.png"/>
            </router-link>
            <v-spacer/>
            <v-card flat :to="{name: 'outlets'}">
                <v-row no-gutters justify="end">
                    <div class="text-right" :class="{'text-small': $vuetify.breakpoint.smAndDown, 'text-caption': $vuetify.breakpoint.mdAndUp}">
                        {{ outlet ? outlet.address : 'Кафе не выбрано' }}
                    </div>
                </v-row>
                <v-row v-if="outlet" no-gutters justify="end" style="margin-top: -4px">
                    <div class="text--secondary text-right" :class="{'text-small': $vuetify.breakpoint.smAndDown, 'text-caption': $vuetify.breakpoint.mdAndUp}">
                        Примерно время готовности {{ closestDuration }} мин
                    </div>
                </v-row>
                <v-row no-gutters justify="end" style="margin-top: -4px">
                    <div class="primary--text" :class="{'text-small': $vuetify.breakpoint.smAndDown, 'text-caption': $vuetify.breakpoint.mdAndUp}">
                        {{ outlet ? 'Изменить кафе' : 'Выбрать кафе' }}
                    </div>
                </v-row>
            </v-card>
        </v-app-bar>
        <promo-list/>
        <product-groups/>
        <v-container class="pa-0">
            <product-group-menu-list/>
        </v-container>
        <div v-if="$store.state.selectedPromo" style="position: fixed; right: 10px; top: 68px; z-index: 100;">
            <v-chip color="primary" close @click:close="$store.state.selectedPromo = null">
                Выбрана акция {{$store.state.selectedPromo.name}}
            </v-chip>
        </div>
        <v-footer color="grey lighten-4" class="mt-3 pa-0" height="80" v-if="!$store.state.loading">
            <v-col class="pa-0 fill-height">
                <v-divider class="grey lighten-3"></v-divider>
                <v-row no-gutters justify="space-between" align="center" class="grey--text text--darken-2 fill-height px-3">
                    <div class="font-weight-medium">© Ростерхит 2021</div>
                    <router-link class="grey--text text--darken-2" :to="{name: 'paymentInfo'}">
                        <div class="body-2">Условия предзаказа товара</div>
                    </router-link>
                </v-row>
            </v-col>
        </v-footer>
    </v-col>
</template>

<script>
    import ProductGroupTile from '../components/productGroup/ProductGroupTile'
    import ProductGroupMenuList from "../components/productGroup/ProductGroupMenuList"
    import PromoList from '../components/PromoList'
    import ProductGroups from "@/main/components/productGroup/ProductGroupsSlider";

    export default {
        name: 'Products',

    components: {
        ProductGroupTile,
        ProductGroupMenuList,
        PromoList,
        ProductGroups
    },

    computed: {
        outlet() {
            const selectedOutletId = this.$store.state.selectedOutletId
            if (selectedOutletId === null) return null
            return this.$outlet.list.find(e => e.id === selectedOutletId)
        },

        closestDuration() {
            if (!this.outlet) return null
            if (!this.outlet.closest_time) return null
            const now = moment.utc()
            const pt = moment.utc(this.outlet.closest_time.pt)
            return pt.diff(now, 'minute')
        },
    },
}
</script>

<style scoped>
    .text-small {
        font-size: 10px !important;
    }
</style>

<style>
    .v-toolbar__content {
        padding: 4px 8px !important;
    }
</style>
