<template>
    <v-col class="pa-0 pb-1">
        <v-row no-gutters class="py-2" align="center">
            <v-img :src="orderItem.product.image || '/static/not-found.png'" contain
                   min-width="40" min-height="40" max-width="40" max-height="40" aspect-ratio="1"/>
            <div class="text-body-2 font-weight-medium pl-3" v-text="orderItem.product.name"/>
            <v-spacer/>
            <div class="text-body-2 font-weight-bold pl-3">
                <span v-if="orderItem.price">{{ orderItem.amount }} x {{ orderItem.price }} ₽</span>
                <span v-else>бесплатно</span>
            </div>
        </v-row>
        <v-row v-for="(itemIngredient, index) in orderItem.ingredients" :key="index"
               no-gutters align="center" justify="space-between" class="pl-2">
            <div class="text-caption text--secondary" style="line-height: 1rem !important">
                <span class="mx-1">-</span> {{ itemIngredient.ingredient.name }}
            </div>
            <div class="text-caption text--secondary" style="line-height: 1rem !important">
                <span v-if="itemIngredient.price">{{ itemIngredient.amount }} x {{ itemIngredient.price }} ₽</span>
                <span v-else>бесплатно</span>
            </div>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: 'OrderProduct',
    props: {
        orderItem: Object
    },
    data: () => ({}),
    methods: {}
}
</script>

<style scoped>

</style>