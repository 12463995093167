<template>
    <div v-if="smsSent" class="ma-0 pa-3 d-flex flex-column align-center justify-center fill-height">
        <div class="d-flex flex-column align-center justify-center">
            <div class="pa-2 pa-md-4 text-h6 text-center text-md-h6 font-weight-medium">
                Введите код из СМС
            </div>
            <password-input :length="6" autofocus @complete="authorize"/>
            <v-card flat color="transparent" class="pa-1 my-2" @click="smsSent = false">
                <div class="text-caption">
                    Изменить номер телефона
                </div>
            </v-card>
        </div>
    </div>
    <div v-else class="ma-0 pa-3 pa-md-4 d-flex flex-column align-center justify-center fill-height">
        <div class="title font-weight-bold text-center">Введите номер телефона</div>
        <v-form ref="form" v-model="form">
            <phone-input v-model="number"/>
            <v-row no-gutters class="pa-2" align="center" style="flex-wrap: nowrap">
                <v-switch v-model="agree" dense class="ma-0 pa-0" hide-details/>
                <p class="text-small ma-0 pa-0 pl-2" style="line-height: 1.2em">
                    Входя или регистрируясь в приложении, я соглашаюсь со
                    <a href="/payment_info" target="_blank"
                       class="primary--text font-weight-bold">
                        сбором и обработкой персональных данных
                    </a>
                </p>
            </v-row>
            <v-row no-gutters align="center" justify="center" class="mt-5">
                <v-btn
                    :disabled="!number || number.length < 11 || !agree" depressed
                    class="rounded-lg" :loading="loading" color="primary"
                    :block="$vuetify.breakpoint.smAndDown" min-height="50" min-width="250" max-height="50"
                    @click="register"
                >
                    <div class="text-none text-body-2 font-weight-medium">
                        Отправить СМС
                    </div>
                </v-btn>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import PasswordInput from "@/main/components/PasswordInput"
import PhoneInput from "@/main/components/PhoneInput";
import moment from "moment";
import {authByPhone} from '@/authByPhone'

export default {
    name: "PhoneLogin",

    components: {
        PhoneInput,
        PasswordInput,
    },

    data: () => ({
        form: false,
        loading: false,
        errors: [],
        agree: false,
        smsSent: false,
        authLoading: false,
        number: null,
    }),

    methods: {
        async register() {
          try {
            this.loading = false;
            await authByPhone(this.$store, this.number)
            this.$store.commit("setSnackbar", {
              color: "success",
              text: "СМС отправлено",
            });
            this.loading = false;
            this.smsSent = true;
          } finally {
            this.loading = false;
          }

        },

        authorize(password) {
            this.authLoading = true
            this.$store.dispatch('login', {
                credentials: {
                    phone: this.number,
                    password,
                }
            }).then(() => {
                this.authLoading = false
                this.$store.state.authModal = false
                this.$router.push({name: 'account'})
            }).catch(() => {
                this.authLoading = false
                this.$store.commit('setSnackbar', {color: 'error', text: 'Неверный пароль'})
            })
        },
    },
}
</script>
