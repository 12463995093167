<template>
  <template-list
    :load-list-params="{ user: $route.query.user }"
    :model="$order"
    :show-create-button="false"
    hide-items
  >
    <template v-slot:actions>
      <v-btn
        v-if="$route.query.user"
        class="rounded-lg text-none body-2 mr-3"
        depressed
        height="40"
        @click="clearFilterByUser()"
        >Очистить фильтр по пользователю
      </v-btn>
      <v-btn
        :loading="$order.loadings.list"
        class="rounded-lg text-none body-2"
        color="primary"
        depressed
        height="40"
        @click="$order.loadList()"
        >Обновить
      </v-btn>
    </template>
    <template v-slot:subactions>
      <v-row align="center" class="my-0" dense>
        <v-col cols="12" md="2" lg="2">
          <date-picker
            v-model="$order.filters.start_date"
            class="rounded-lg"
            clearable
            label="Дата начала"
            with-time
          ></date-picker>
        </v-col>
        <v-col cols="12" md="2">
          <date-picker
            v-model="$order.filters.end_date"
            class="rounded-lg"
            clearable
            label="Дата окончания"
            with-time
          ></date-picker>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            v-model="$order.filters.outlet"
            :item-text="(v) => v.number + '. ' + v.address"
            :items="outletsItems"
            class="rounded-lg"
            clearable
            dense
            hide-details
            item-value="vendor_code"
            label="Торговая точка"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="$order.filters.search"
            class="rounded-lg"
            clearable
            dense
            hide-details
            label="Поиск"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <div style="display: flex; gap: 10px">
            <v-menu :close-on-content-click="false" offset-x>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="rounded-lg text-none body-2"
                  color="primary"
                  depressed
                  height="40"
                  v-on="on"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-card class="pa-3">
                <v-row align="center" class="ma-0" justify="space-between">
                  <div class="subtitle-2">Фильтрация</div>
                  <v-btn text x-small @click="clearFilter()"
                    >Сбросить фильтр</v-btn
                  >
                </v-row>
                <v-select
                  v-model="$order.filters.offline_with_user"
                  :items="[{ label: 'Да', value: true }]"
                  class="rounded-lg mt-2"
                  dense
                  clearable
                  hide-details
                  item-value="value"
                  item-text="label"
                  label="Гость указал телефон"
                  outlined
                ></v-select>
                <v-select
                  v-model="$order.filters.source"
                  :items="$order.sources"
                  class="rounded-lg mt-2"
                  dense
                  clearable
                  hide-details
                  item-value="value"
                  item-text="label"
                  label="Источник заказа"
                  outlined
                ></v-select>
              </v-card>
            </v-menu>
            <v-menu
              v-if="$auth.hasRight('balance__access')"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="rounded-lg"
                  color="primary"
                  height="40"
                  outlined
                  v-on="on"
                  style="flex: 1"
                  >Баллы
                </v-btn>
              </template>

              <v-card class="pa-3">
                <div class="my-2 font-weight-bold">Начисленные баллы</div>
                <v-row class="flex-nowrap" no-gutters>
                  <v-text-field
                    v-model="$order.filters.min_refiled_balance"
                    class="rounded-lg mr-3"
                    clearable
                    dense
                    hide-details
                    label="Минимальное"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="$order.filters.max_refiled_balance"
                    class="rounded-lg"
                    clearable
                    dense
                    hide-details
                    label="Максимальное"
                    outlined
                  ></v-text-field>
                </v-row>
                <div class="my-2 font-weight-bold">Списанные баллы</div>
                <v-row class="flex-nowrap" no-gutters>
                  <v-text-field
                    v-model="$order.filters.min_withdraw_balance"
                    class="rounded-lg mr-3"
                    clearable
                    dense
                    hide-details
                    label="Минимальное"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="$order.filters.max_withdraw_balance"
                    class="rounded-lg"
                    clearable
                    dense
                    hide-details
                    label="Максимальное"
                    outlined
                  ></v-text-field>
                </v-row>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <order-view-dialog v-model="dialog"></order-view-dialog>
    </template>
    <template v-slot:items>
      <OrdersTable :items="$order.list" />
    </template>
  </template-list>
</template>

<script>
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog";
import OrderBalanceView from "@/admin/views/orders/OrderBalanceView.vue";
import OrderStatus from "@/admin/views/orders/OrderStatus.vue";
import OrdersTable from "@/admin/views/orders/OrdersTable.vue";
import { cloneDeep } from "lodash";

export default {
  name: "Orders",
  components: {
    OrdersTable,
    OrderStatus,
    OrderBalanceView,
    OrderViewDialog,
  },
  data: () => ({
    dialog: false,
    outletsItems: [],
  }),
  methods: {
    clearFilter() {
      this.$order.filters.source = null
      this.$order.filters.offline_with_user = null
    },
    orderFiscalizationStatus(order) {
      return this.$order.getOrderFiscalizationStatus(order);
    },
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
    openDialog(id) {
      this.$order.loadItem(id).then(() => {
        this.dialog = true;
      });
    },
    async clearFilterByUser() {
      await this.$router.replace({ name: "orderList", query: {} });
      window.location.reload();
    },
  },
  beforeCreate() {
    this.$order.filters.user = this.$route.query.user;
  },
  created() {
    this.$outlet.loadList();
  },
  watch: {
    "$outlet.list"(v) {
      const res = cloneDeep(v);
      res.forEach((v) => (v.disabled = undefined));
      this.outletsItems = res;
    },
  },
  // computed: {
  //   outletsItems() {
  //     const items = this.$outlet.list
  //     return cloneDeep(items).forEach(v => v.disabled = undefined);
  //   }
  // }
};
</script>

<style scoped></style>
