<template>
  <v-col class="pa-0">
    <v-row no-gutters class="my-3" align="center">
      <div class="text-h6 mr-3">Общая информация</div>
      <v-divider />
      <v-btn
        @click="$emit('update')"
        depressed
        color="primary"
        class="ml-3 rounded-lg text-none body-2"
        >Сохранить
      </v-btn>
    </v-row>
    <div class="text-h5" v-text="item.address" />
    <v-row no-gutters class="mb-2" align="center">
      <div class="text-caption text--secondary" v-text="item.company.name" />
      <div class="text-caption mx-2">|</div>
      <div
        class="text-caption text--secondary"
        v-if="item.coords && item.coords.length === 2"
      >
        Координаты: {{ item.coords[0] }}, {{ item.coords[1] }}
      </div>
      <div class="text-caption" v-else>Координаты не указаны</div>
    </v-row>

    <v-col no-gutters class="mb-5 mt-5 pa-0">
      <v-text-field
        class="rounded-lg"
        v-model="item.full_address"
        label="Полный адрес"
        outlined
        hide-details
        dense
      ></v-text-field>
      <v-select
        dense
        outlined
        hide-details
        label="Интерфейс сотрудника"
        :items="workerInterfaces"
        v-model="item.worker_interface"
        item-text="title"
        item-value="value"
        class="mt-4"
      ></v-select>
      <v-col class="pa-0" v-if="item.worker_interface === 'tracker'">
        <v-checkbox
          label="Отображать заказы из 1С на трекере"
          hide-details
          dense
          v-model="item.show_in_tracker_from_accounting_system"
        ></v-checkbox>
        <v-checkbox
          label="Отображать заказы из приложения на трекере"
          hide-details
          dense
          v-model="item.show_in_tracker_from_app"
        ></v-checkbox>
        <v-checkbox
          label="Расчет очереди из 1С заказов"
          hide-details
          dense
          v-model="item.compute_locked_periods_for_accounting_system"
        ></v-checkbox>
        <v-checkbox
            label="Яндекс еда"
            hide-details
            dense
            v-model="item.yandex_eda_enabled"
        ></v-checkbox>
      </v-col>
      <!--            <v-btn @click="deleteCookItems" outlined color="red" height="40px" class="text-none ml-3 body-2">Удалить-->
      <!--                блюда из трекера-->
      <!--            </v-btn>-->
    </v-col>

    <v-textarea
      dense
      hide-details
      no-resize
      outlined
      auto-grow
      :rows="5"
      label="Описание"
      v-model="item.description"
    />
    <v-row no-gutters class="mt-5">
      <ImageUploader
        @delete="deleteImage(image, i)"
        v-for="(image, i) in images"
        :key="i"
        width="200"
        height="200"
        class="mr-3"
        can-delete
        @input="uploadImage($event, image)"
        :image="image.image"
      ></ImageUploader>
      <ImageUploader
        width="200"
        height="200"
        class="mr-3"
        @input="uploadImage($event, null)"
      ></ImageUploader>
    </v-row>
  </v-col>
</template>

<script>
import imageUpload from "@/vendor/imageUpload";
import ImageUploader from "@/admin/components/ImageUploader";

export default {
  name: "OutletItemBase",
  props: {
    item: Object,
  },
  emits: ["update"],
  components: {
    ImageUploader,
  },
  mixins: [imageUpload],
  computed: {
    images() {
      return this.$outlet.item.images;
    },
  },
  data: () => ({
    workerInterfaces: [
      { title: "Сервисное приложение", value: "service_app" },
      { title: "Трекер заказов/блюд", value: "tracker" },
    ],
    workerInterface: null,
  }),
  methods: {
    openImage(i) {
      this.$eventBus.$emit("setImages", this.images);
      this.$eventBus.$emit("setIndex", i);
    },

    deleteImage(image, index) {
      this.item.images.splice(index, 1);
      this.$outlet.sendDelete("delete_image", {
        id: image.id,
      });
      this.$store.commit("setSnackbar", {
        color: "success",
        text: "Изображение удалено",
      });
    },

    async uploadImage(file, image) {
      const formData = new FormData();
      formData.append("image", file);
      if (image) formData.append("id", image.id);
      const res = await this.$outlet.sendPutSingle(
        "upload_image",
        this.item.id,
        formData
      );
      if (image) {
        image.image = res.url;
      } else {
        this.item.images.push({
          id: res.id,
          image: res.url,
        });
      }
      this.$store.commit("setSnackbar", {
        color: "success",
        text: "Изображение сохранено",
      });
    },

    async onImageUploaded() {
      const images = await this.imageUploaded("image-uploader", true);
      for (const image of images) {
        this.images.push({
          image,
        });
      }
    },

    deleteCookItems() {
      this.$outlet
        .sendDeleteSingle("delete_cook_items", this.$outlet.item.id)
        .then((res) => {
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Удалено: " + res.count,
          });
        });
    },
  },
};
</script>

<style scoped></style>
