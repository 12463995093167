<template>
    <div>
        <v-card
            v-if="$vuetify.breakpoint.mdAndUp || panel"
            :width="$vuetify.breakpoint.mdAndUp ? 400 : '100%'"
            :max-height="$vuetify.breakpoint.mdAndUp ? '70vh' : '100%'"
            :height="$vuetify.breakpoint.mdAndUp ? 'auto' : '100%'"
            class="rounded-l ma-0 ma-md-2"
            style="position: absolute; z-index: 2; overflow-y: scroll;"
        >
            <v-row no-gutters class="pa-3" align="center">
                <div class="text-subtitle-1 font-weight-medium">Торговые точки</div>
                <v-spacer/>
                <v-btn icon @click="panel = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-row>
            <v-divider/>
            <div>
                <v-row no-gutters v-for="(outlet, index) in outlets" :key="index">
                    <v-col cols="10" class="rounded-right cursor-pointer" @click="toggle(outlet)" v-ripple>
                        <v-row dense align="center" class="pa-2">
                            <v-col cols="2">
                                <v-img class="rounded" :src="getOutletImage(outlet)" aspect-ratio="1">
                                    <div v-if="isSelected(outlet)" class="d-flex align-center justify-center"
                                         style="height: 100%; width: 100%; background-color: rgba(0,0,0,0.5)">
                                        <v-icon color="primary">
                                            mdi-check
                                        </v-icon>
                                    </div>
                                </v-img>
                            </v-col>
                            <v-col cols="10">
                                <div class="text-body-2 noselect" v-text="outlet.address"/>
                                <div class="text-caption noselect" v-text="getScheduleAsText(outlet)"/>
                                <div v-if="outlet.closest_time" class="text-caption noselect">
                                    Ближайшее время заказа {{ calendarTime(outlet.closest_time.pt) }}
                                </div>
                                <div class="text-caption red--text" v-if="cartItems.length && !outlet.closest_time">
                                    Недоступно для заказа
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="2">
                        <v-row no-gutters align="center" justify="center" class="fill-height">
                            <v-btn icon @click="$outlet.openDialog(outlet.id)">
                                <v-icon color="primary">mdi-information-outline</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <div id="map" style="height: calc(100vh - 60px); z-index: 1;"/>
        <v-btn
            v-if="$vuetify.breakpoint.smAndDown && !panel" @click="panel = true"
            fab style="position: fixed; bottom: 70px; right: 10px; z-index: 10;"
        >
            <v-icon>
                mdi-format-list-bulleted-square
            </v-icon>
        </v-btn>
        <outlet-modal/>
    </div>
</template>

<script>
import datetime from "../../vendor/datetime";
import OutletModal from "./OutletModal";

export default {
    name: "Outlets",

    components: {
        OutletModal,
    },

    mixins: [datetime],

    data: () => ({
        panel: false,
        pointsLayer: null
    }),

    computed: {
        outlets() {
            return this.$outlet.list
        },

        selectedId() {
            return this.$store.state.selectedOutletId
        },
        cartItems() {
            return this.$cart.getCartItemIds(this.$store.state.cartItems)
        }
    },

    methods: {
        toggle(outlet) {
            if (this.isSelected(outlet)) {
                this.$store.commit('setOutlet', null)
            } else {
                if (this.cartItems.length && !outlet.closest_time)
                    return
                this.$store.commit('setOutlet', outlet)
            }
        },

        getOutletImage(outlet) {
            if (outlet.images.length === 0) return '/static/not-found.png'
            return outlet.images[0].image
        },

        getScheduleAsText(outlet) {
            if (outlet.schedules.length === 7) return 'Ежедневно'
            return outlet.schedules.map(e => this.formatWorkDay(e.work_day)).join(', ')
        },

        async loadList() {
            const outlets = await this.$outlet.loadList({
                cart_items: this.cartItems,
                external_computing: true
            })
            this.addOutletsToMap(outlets)
        },

        addOutletsToMap(outlets) {
            const points = []
            for (const outlet of outlets) {
                const {id, coords, closest_time} = outlet
                points.push({id, coords: [coords[1], coords[0]], closest_time})
            }
            const func = (val) => {
                return {
                    pt: val.closest_time
                }
            }

            const pointCollection = this.$L.pointCollection(points, func)

            const layer = L.geoJson(pointCollection, {
                onEachFeature: (feature, layer) => {
                    layer.on('click', () => this.$outlet.openDialog(feature.id))
                },
                pointToLayer: (feature, latlng) => {
                    const pt = feature.properties.pt
                    let draw_icon
                    if (!pt) {
                        draw_icon = new L.icon({
                            iconUrl: this.cartItems.length && !pt ? '/static/logo-short-grey.png' : '/static/logo-short.png',
                            iconSize: [20, 28],
                        })
                    } else {
                        const now = moment().local()
                        const currentPt = moment.utc(pt.pt).local()
                        const diff = currentPt.diff(now, 'minute') + 1
                        draw_icon = new L.divIcon({
                            className: 'custom-div-icon',
                            html: `<div class="map-circle green white--text">
                                        <div class="font-weight-medium" style="height: 13px">${diff}</div>
                                   <div>мин</div></div>`,
                            iconSize: [40, 40],
                            iconAnchor: [15, 42]
                        })
                    }
                    return L.marker(latlng, {
                        icon: draw_icon
                    })
                },
            })

            this.$L.drawLayer(this, 'pointsLayer', layer, false)
        },

        isSelected(outlet) {
            return outlet.id === this.selectedId
        },
    },

    mounted() {
        this.$L.initMap('map', [54.5, 20.5])

        if (this.$outlet.list.length)
            this.addOutletsToMap(this.$outlet.list)

        this.loadList()
    }
}
</script>

<style>
.rounded-right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.map-circle {
    height: 40px;
    width: 40px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
}
</style>
