<template>
  <v-col class="pa-3 pb-10">
    <v-col class="pa-0">
      <v-row align="center" no-gutters>
        <v-btn v-if="$route.name !== 'analytics'" :to="{name: 'analytics'}" class="mr-3" icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="text-h4 font-weight-bold">{{tabName}}</div>
      </v-row>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'analyticsBase'}" class="rounded-lg mt-5" color="primary" outlined width="320">Общая
          аналитика
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'analyticsDeclined'}" class="rounded-lg mt-5" color="primary" outlined width="320">
          Утилизированные заказы
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'analyticsOverdue'}" class="rounded-lg mt-5" color="primary" outlined width="320">
          Просроченные заказы
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'asSoonAsPossible'}" class="rounded-lg mt-5" color="primary" outlined width="320">
          Заказы "Как можно скорее"
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'trackerActivities'}" class="rounded-lg mt-5" color="primary" outlined width="320">
          Состояние трекеров
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'disabledNomenclatureAnalytics'}" class="rounded-lg mt-5" color="primary" outlined
               width="320">
          Заблокированная номенклатура
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'outletNomenclatureAmountAnalytics'}" class="rounded-lg mt-5" color="primary" outlined
               width="320">
          Остатки номенклатуры
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'couriersShiftsAnalytics'}" class="rounded-lg mt-5" color="primary" outlined
               width="320">
          Аналитика по курьерам
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'guestComebackAnalytics'}" class="rounded-lg mt-5" color="primary" outlined
               width="320">
          Аналитика "Возвращение гостя"
        </v-btn>
      </div>
      <div v-if="$route.name === 'analytics'">
        <v-btn :to="{name: 'orderReviewTagsAnalytics'}" class="rounded-lg mt-5" color="primary" outlined
               width="320">
          Аналитика по отзывам
        </v-btn>
      </div>

    </v-col>
    <router-view></router-view>
  </v-col>
</template>

<script>

export default {
  name: 'Analytics',
  computed: {
    tabName() {
      const name = this.$route.name
      if (name === 'analyticsBase') return 'Аналитика / Общая аналитика'
      if (name === 'analyticsDeclined') return 'Аналитика / Утилизированные заказы'
      if (name === 'analyticsOverdue') return 'Аналитика / Просроченные заказы'
      if (name === 'asSoonAsPossible') return 'Аналитика / Заказы "Как можно скорее"'
      if (name === 'trackerActivities') return 'Аналитика / Состояние трекеров'
      if (name === 'disabledNomenclatureAnalytics') return 'Аналитика / Заблокированная номенклатура'
      if (name === 'outletNomenclatureAmountAnalytics') return 'Аналитика / Остатки номенклатуры'
      if (name === 'couriersShiftsAnalytics') return 'Аналитика / Смены курьеров'
      return 'Аналитика'
    }
  }
}
</script>

<style scoped>

</style>
