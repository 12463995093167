<template>
  <v-dialog
    v-model="$store.state.productDialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="700"
    scrollable
  >
    <v-card
      v-if="product && step === 1"
      color="background"
      max-height="600"
      style="overflow-y: auto"
    >
      <v-row v-if="$vuetify.breakpoint.smAndDown" no-gutters>
        <div class="pt-2 pl-1">
          <v-btn icon @click="close">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
      </v-row>
      <v-card class="rounded-lg elevation-1 ma-3">
        <v-row align="center" no-gutters>
          <v-col class="pa-3" cols="3">
            <v-img :src="image" aspect-ratio="1" contain />
          </v-col>
          <v-col class="pa-3" cols="9">
            <div v-if="$store.state.showAllInfo" class="font-weight-medium">
              id: {{ product.id }}
            </div>
            <div
              class="text-subtitle-1 text-md-h5 font-weight-medium"
              v-text="product.name"
            />
          </v-col>
        </v-row>
        <v-row
          align="center"
          class="py-3 px-5"
          justify="space-between"
          no-gutters
        >
          <amount-changer
            :amount="amount"
            :small="$vuetify.breakpoint.smAndDown"
            @decrement="decrement"
            @increment="increment"
          />
          <div class="text-subtitle-1 text-md-h5 font-weight-bold">
            {{ totalPrice }} ₽
          </div>
        </v-row>
        <v-col v-if="product.description" class="pa-0">
          <v-row justify="center" no-gutters>
            <div
              class="pb-3 text-caption text--secondary cursor-pointer"
              @click="showDescription = !showDescription"
            >
              Показать описание
            </div>
          </v-row>
          <div
            v-if="showDescription"
            class="pa-3 text-body-2"
            style="white-space: pre-line"
          >
            {{ product.description }}
          </div>
        </v-col>
      </v-card>
      <ingredient-group
        v-for="(ingredientGroup, index) in productIngredientGroups"
        :key="index"
        :product-ingredient-group="ingredientGroup"
      />
      <div style="min-height: 65px" />
      <div
        class="pa-2"
        style="
          position: sticky;
          bottom: 0;
          z-index: 10;
          background-color: transparent;
          width: 100%;
        "
      >
        <v-btn
          block
          class="elevation-0"
          color="primary rounded-lg"
          height="50"
          @click="addToCart"
        >
          <div class="text-none text-body-2">
            {{ cartIndex > -1 ? "Изменить" : "Добавить в корзину" }}
          </div>
        </v-btn>
      </div>
    </v-card>
    <v-card
      v-if="product && step === 2"
      color="primary"
      max-height="600"
      style="overflow-y: auto"
      tile
    >
      <v-row align="center" class="pa-3" no-gutters>
        <div class="text-h6">Предложения к заказу</div>
      </v-row>
      <v-divider />
      <v-row class="pa-3">
        <v-col v-for="(upSale, index) in upSales" :key="index" cols="6" md="4">
          <v-card class="pa-2 rounded-lg" @click="toggleUpSale(upSale)">
            <v-img :src="upSale.image || '/media/not-found.png'" />
            <v-row align="center" class="pt-1" justify="center" no-gutters>
              <div class="text-body-2" v-text="upSale.name" />
            </v-row>
            <v-icon
              color="primary"
              style="position: absolute; top: 10px; left: 10px"
            >
              {{
                isUpSaleSelected(upSale)
                  ? "mdi-check-circle-outline"
                  : "mdi-circle-outline"
              }}
            </v-icon>
            <div
              class="text-body-1 font-weight-bold"
              style="position: absolute; top: 10px; right: 10px"
            >
              {{ getPrice(upSale) }} ₽
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div
        class="pa-2"
        style="
          position: sticky;
          bottom: 0;
          z-index: 10;
          background-color: transparent;
          width: 100%;
        "
      >
        <v-btn
          block
          class="rounded-lg"
          color="dark"
          dark
          depressed
          height="60"
          @click="addUpSalesToCart"
        >
          <div class="text-none text-body-2 px-10">
            {{
              selectedUpSales.length > 0
                ? "Добавить в корзину"
                : "Может в другой раз..."
            }}
          </div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import IngredientGroup from "./IngredientGroup";
import AmountChanger from "./AmountChanger";

export default {
  name: "ProductDialog",

  components: {
    IngredientGroup,
    AmountChanger,
  },

  data: () => ({
    amount: 1,
    showDescription: false,
    cartIndex: -1,
    step: 1,
    selectedUpSales: [],
  }),

  computed: {
    product() {
      return this.$store.state.items.product.item;
    },

    productIngredientGroups() {
      return this.product.product_ingredient_groups;
    },

    image() {
      const notFoundImage = "/static/not-found.png";
      if (!this.product) return notFoundImage;
      return this.product.image || notFoundImage;
    },

    selectedProductIngredients() {
      return this.$store.state.selectedProductIngredients;
    },

    totalPrice() {
      return this.$product.countTotalPrice(
        this.selectedProductIngredients,
        this.amount
      );
    },

    cartItems() {
      return this.$store.state.cartItems;
    },

    upSales() {
      return this.$upSale.getProductUpSales(this.product);
    },
  },

  methods: {
    close() {
      this.$store.state.productDialog = false;
      setTimeout(() => {
        this.$store.state.selectedProduct = null;
        this.$store.state.selectedProductIngredients = [];
        this.selectedUpSales = [];
        this.step = 1;
      }, 300);
    },

    increment() {
      this.amount++;
    },

    decrement() {
      if (this.amount === 1) return;
      this.amount--;
    },

    setDefaultIngredients(productIngredients = []) {
      if (productIngredients.length)
        this.$store.state.selectedProductIngredients = productIngredients;
      else
        this.$store.state.selectedProductIngredients =
          this.$product.getDefaultIngredients();
    },

    addToCart() {
      const obj = {
        amount: this.amount,
        product: this.product,
        productIngredients: _.orderBy(this.selectedProductIngredients, ["id"]),
      };
      if (this.cartIndex > -1) {
        this.$store.state.cartItems[this.cartIndex].amount = obj.amount;
        this.$store.state.cartItems[this.cartIndex].product = obj.product;
        this.$store.state.cartItems[this.cartIndex].productIngredients =
          obj.productIngredients;
        this.close();
      } else {
        const cartItemIndex = this.$store.state.cartItems.findIndex((val) =>
          _.isEqual(val, obj)
        );
        if (cartItemIndex > -1)
          this.$store.state.cartItems[cartItemIndex].amount += this.amount;
        else this.$store.state.cartItems.push(obj);
        this.showUpSales();
      }
      this.$store.dispatch("saveCart");
    },

    async openProduct(product) {
      const productIngredients = this.$product.getDefaultIngredients(product);
      this.$store.commit("setLoading", true);
      this.$product.item = product;
      this.setDefaultIngredients(productIngredients);
      this.$store.state.productDialog = true;
      this.$store.commit("setLoading", false);
    },

    showUpSales() {
      if (this.upSales) {
        this.step = 2;
      } else {
        this.close();
      }
    },

    getPrice(product) {
      const productIngredients = this.$product.getDefaultIngredients(product);
      return this.$product.countTotalPrice(productIngredients, 1);
    },

    toggleUpSale(upSale) {
      if (this.isUpSaleSelected(upSale)) {
        const index = this.selectedUpSales.indexOf(upSale);
        this.selectedUpSales.splice(index, 1);
      } else this.selectedUpSales.push(upSale);
    },

    isUpSaleSelected(upSale) {
      return this.selectedUpSales.indexOf(upSale) > -1;
    },

    addUpSalesToCart() {
      if (this.selectedUpSales.length > 0) {
        for (const upSale of this.selectedUpSales) {
          const productIngredients =
            this.$product.getDefaultIngredients(upSale);
          const obj = {
            amount: 1,
            product: upSale,
            productIngredients: productIngredients,
          };
          const cartItemIndex = this.$store.state.cartItems.findIndex((val) =>
            _.isEqual(val, obj)
          );
          if (cartItemIndex > -1)
            this.$store.state.cartItems[cartItemIndex].amount += 1;
          else this.$store.state.cartItems.push(obj);
        }
      }

      this.close();
    },
  },

  created() {
    this.$eventBus.$on("openProduct", async (val) => {
      this.$store.state.loading = true;
      const product = await this.$product
        .loadItem(val.product.id)
        .finally(() => {
          this.$store.state.loading = false;
        });
      this.cartIndex = val.cartIndex > -1 ? val.cartIndex : -1;
      this.amount = val.amount || 1;
      void this.openProduct(product);
    });

    // const product = await this.$product.loadItem(val.product.id)
    // this.cartIndex = val.cartIndex > -1 ? val.cartIndex : -1
    // this.amount = val.amount || 1
    // void this.openProduct(product, product.product_ingredient_groups || [])
  },

  watch: {
    "$store.state.productDialog"(val) {
      if (!val) this.close();
    },
  },
};
</script>