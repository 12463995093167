<template>
    <template-item
        content-classes="pa-0"
        :show-header="false"
        :title="title" :show-actions="false" :model="$airIngredient" :show-delete="false" v-slot="{object}">
        <v-row align="center" class="px-4 mb-6 mt-4">
            <div class="text-h6 mr-3">Модификатор</div>
            <v-divider/>
        </v-row>
        <v-row no-gutters align="center">
            <v-btn @click="$router.replace({name: 'productIngredientBase'})"
                   :color="$route.name === 'productIngredientBase' ? 'primary' : ''" depressed
                   class="mr-3 rounded-lg text-none body-2">Общая информация
            </v-btn>
            <v-btn @click="$router.replace({name: 'productIngredientNutritional'})"
                   :color="$route.name === 'productIngredientNutritional' ? 'primary' : ''" depressed
                   class="mr-3 rounded-lg text-none body-2">Пищевая ценность
            </v-btn>
            <v-btn @click="$router.replace({name: 'productIngredientNomenclature'})"
                   :color="$route.name === 'productIngredientNomenclature' ? 'primary' : ''"
                   depressed class="mr-3 rounded-lg text-none body-2">Технологическая карточка
            </v-btn>
            <v-btn @click="$router.replace({name: 'productIngredientPrices'})"
                   :color="$route.name === 'productIngredientPrices' ? 'primary' : ''" depressed
                   class="mr-3 rounded-lg text-none body-2">Цены
            </v-btn>
        </v-row>
        <router-view></router-view>
    </template-item>
</template>

<script>
export default {
    name: "ProductItem",

    components: {},
    data: () => ({}),
    computed: {
        title() {
            const values = []
            if (this.$product.item) {
                if (this.$product.item.group) {
                    values.push(this.$product.item.group.vendor_name || 'Отсутствует')
                }
                values.push(this.$product.item.vendor_name || 'Отсутствует')
            }
            values.push(this.$airIngredient.item.vendor_name || 'Отсутствует')
            return values.join(' / ')
        }
    },
    methods: {},
    created() {
        this.$ingredientNomenclature.loadList({
            ingredient: this.$airIngredient.item.id
        })
    }
}
</script>
