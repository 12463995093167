<template>
    <template-item :model="$productGroup" :show-delete="false" v-slot="{object, rules}" title-prepend="Группы блюд / " object-key-for-title="name">
        <div>Наименование из учетной системы: <span class="font-weight-bold">{{ object.vendor_name || 'Отсутствует' }}</span></div>
        <v-text-field v-model="object.name" outlined hide-details dense label="Клиентское наименование"
                      class="mt-4"/>
        <v-text-field v-model="object.sorting" type="number" outlined hide-details dense label="Сортировка"
                      class="mt-4"/>
        <v-checkbox class="pa-0 ma-0 py-4" hide-details label="Отображать в приложении" v-model="object.show_in_app"></v-checkbox>
    </template-item>
</template>

<script>
export default {
    name: "ProductGroupItem",
}
</script>
