<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Оборудование</div>
            <v-divider/>
        </v-row>
        <v-data-table
            no-data-text="Нет данных"
            hide-default-footer
            :headers="headers"
            :items="items"
            :items-per-page="999999"
        >
            <template v-slot:item.active="{ item }">
                <v-checkbox class="ma-0 pa-0" hide-details
                            v-model="item.active" readonly
                ></v-checkbox>
            </template>
            <template v-slot:item.service="{ item }">
                <v-btn @click="openService(item.id)" depressed small>{{ getServiceById(item.id).length }}</v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="600">
            <v-card class="pa-5">
                <v-row no-gutters class="mb-3" align="center">
                    <div class="font-weight-bold">Сервисное обслуживание</div>
                    <v-spacer></v-spacer>
                    <v-btn small depressed @click="serviceForEdit.push({
                        equipment_config: selectedConfig,
                        start_time: '01:00',
                        end_time: '02:00',
                        active: true
                    })">Добавить
                    </v-btn>
                </v-row>
                <div v-if="!showServiceForEdit.length">Нет данных</div>
                <v-col class="pa-0"
                       v-for="(el, index) in showServiceForEdit"
                       :key="index">
                    <v-divider v-if="index" class="my-3"></v-divider>
                    <v-row no-gutters align="center">
                        <v-checkbox class="ma-0 pa-0 mr-3" hide-details
                                    v-model="el.active"
                        ></v-checkbox>
                        <v-text-field class="mr-3"
                                      dense hide-details outlined
                                      label="Время начала"
                                      v-model="el.start_time"
                                      type="time"
                        ></v-text-field>
                        <v-text-field class="mr-3"
                                      dense hide-details outlined
                                      label="Время окончания"
                                      v-model="el.end_time"
                                      type="time"
                        ></v-text-field>
                        <v-btn icon @click="serviceToDelete.push(el.id)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
                <v-btn :loading="serviceLoading" @click="saveService" color="primary"
                       class="rounded-lg body-2 mt-3 text-none" depressed>
                    Сохранить
                </v-btn>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
export default {
    name: 'OutletItemEquipments',
    data: () => ({
        dialog: false,
        serviceForEdit: [],
        serviceToDelete: [],
        serviceLoading: false,
        drawnItems: [],
        selectedConfig: null,
        headers: [
            {text: 'Наименование', value: 'name'},
            {text: 'Количество слотов', value: 'slot_amount'},
            {text: 'Действует', value: 'active'},
            {text: 'Обслуживание', value: 'service'},
        ]
    }),
    methods: {
        openService(id) {
            this.selectedConfig = id
            this.serviceToDelete = []
            this.serviceForEdit = this.$outlet.copy(this.getServiceById(id))
            this.dialog = true
        },
        getServiceById(id) {
            return this.service.filter(v => v.equipment_config.id === id)
        },
        async saveService() {
            this.serviceLoading = true
            for (const v of this.serviceToDelete) {
                await this.$equipmentConfigService.delete(v)
            }
            for (const v of this.showServiceForEdit) {
                await this.$equipmentConfigService.updateOrCreate(v)
            }
            this.$equipmentConfigService.loadList({page_size: 'all', outlet: this.$outlet.item.id})
            this.dialog = false
            this.serviceLoading = false
            this.$store.commit('setSnackbar', {color: 'success', text: 'Обслижвание сохранено'})
        }
    },
    computed: {
        items() {
            return this.$equipmentConfig.list
        },
        service() {
            return this.$equipmentConfigService.list
        },
        showServiceForEdit() {
            return this.serviceForEdit.filter(v => this.serviceToDelete.indexOf(v.id) === -1)
        }
    },
    mounted() {
        this.$equipmentConfig.list = []
        this.$equipmentConfigService.list = []
        this.$equipmentConfig.loadList({page_size: 'all', outlet: this.$outlet.item.id})
        this.$equipmentConfigService.loadList({page_size: 'all', outlet: this.$outlet.item.id})
    }
}
</script>

<style scoped>

</style>