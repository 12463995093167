<template>
    <v-container>
        <v-row no-gutters justify="center" class="py-10">
            <v-img max-width="60%" src="/static/logo-long.png"/>
        </v-row>
        <div class="d-flex align-center justify-center pt-10 fill-height">
            <div>
                <v-row no-gutters justify="center" class="py-5">
                    <v-icon color="primary" :size="100">mdi-credit-card-remove-outline</v-icon>
                </v-row>
                <v-row no-gutters justify="center" class="py-5">
                    <div class="text-h4 text-center font-weight-regular">
                        Не удалось <br> оплатить заказ
                    </div>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
    export default {
        name: "PaymentFail",
        created() {
            this.$store.state.loading = false
        },
    }
</script>
