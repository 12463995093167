<template>
    <v-col class="pa-0">
        <v-row no-gutters class="my-3" align="center">
            <div class="text-h6 mr-3">Очередь на трекере</div>
            <OutletQueueData class="mr-3" :outlet-id="$outlet.item.id"></OutletQueueData>
            <v-divider/>
        </v-row>
        <v-row no-gutters align="center" class="mb-3">

            <v-btn @click="loadList" depressed class="rounded-lg text-none body-2" :loading="loading" color="primary">
                Обновить
            </v-btn>
            <div class="ml-3" v-if="!loading && !items.length">Очередь пуста</div>
            <div class="ml-3" v-if="items.length">Количество: {{ items.length }}</div>
            <v-spacer></v-spacer>
            <v-btn v-if="items.length" @click="deleteItems(null)" depressed
                   class="rounded-lg white--text text-none body-2"
                   :loading="deleteLoading" color="red">
                Очистить очередь
            </v-btn>
        </v-row>
        <v-checkbox v-model="onlyActive" label="Только активные" dense hide-details class="pa-0 ma-0 mb-4"></v-checkbox>
        <order-view-dialog v-model="orderDialog"></order-view-dialog>
        <OrdersTable :hide-columns="['outlet']" :items="items"/>
        <v-dialog v-model="dialog" max-width="500">
            <v-card class="pa-10">
                <v-row no-gutters class="text-center" justify="center">
                    <div v-if="!toDelete">Вы уверены что хотите полностью очистить очередь?</div>
                    <div v-else>Вы уверены что хотите удалить заказ из очереди?</div>
                </v-row>
                <v-row no-gutters justify="center" class="mt-5">
                    <v-btn depressed color="red" class="white--text mr-2 text-none body-2" @click="clearQueue">
                        Очистить
                    </v-btn>
                    <v-btn depressed color="secondary" class="ml-2 text-none body-2" @click="dialog=false">Отмена
                    </v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog";
import OrderStatus from "@/admin/views/orders/OrderStatus.vue";
import OrdersTable from "@/admin/views/orders/OrdersTable.vue";
import OutletQueueData from "@/admin/views/outlets/OutletQueueData.vue";

export default {
    name: 'OutletTrackerQueue.vue',
    data: () => ({
        onlyActive: false,
        loading: false,
        results: [],
        dialog: false,
        toDelete: null,
        deleteLoading: false,
        orderDialog: false
    }),
    components: {
        OutletQueueData,
        OrdersTable,
        OrderStatus,
        OrderViewDialog,
    },
    computed: {
        items() {
            let items = this.results.filter(v => v.show_in_tracker)
            if (this.onlyActive) {
                items = items.filter(v => ![0, 6].includes(v.user_status))
            }
            return items
        }
    },
    methods: {
        async loadList() {
            this.loading = true
            this.results = await this.$order.sendGet('by_outlets', {
                outlet: this.$outlet.item.id,
                hours: 24
            })
            this.loading = false
        },
        parseDate(date) {
            return window.moment.localDateTime(date)
        },
        openDialog(id) {
            this.$order.loadItem(id).then(() => {
                this.orderDialog = true
            })
        },
        deleteItems(value = null) {
            this.toDelete = value
            this.dialog = true
        },
        async clearQueue() {
            this.dialog = false
            this.deleteLoading = true
            await this.$order.sendPost('clear_on_tracker', {
                orders: this.toDelete ? [this.toDelete.id] : this.items.map(v => v.id)
            })
            if (this.toDelete) this.toDelete.show_in_tracker = false
            else this.items.forEach(v => v.show_in_tracker = false)
            this.deleteLoading = false
        }
    },
    created() {
        this.loadList()
    }
}
</script>

<style scoped>

</style>