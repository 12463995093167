<template>
  <v-col class="pa-0">
    <v-row no-gutters class="my-3" align="center">
      <div class="text-h6 mr-3">Настройки доставки</div>
      <v-divider />
      <v-btn
        @click="$emit('update')"
        depressed
        color="primary"
        class="ml-3 rounded-lg text-none body-2"
        >Сохранить
      </v-btn>
    </v-row>

    <v-col no-gutters class="mb-5 mt-5 pa-0" cols="5">
      <v-select
        dense
        outlined
        hide-details
        label="Курьер может брать заказы сам"
        :items="[
          { title: 'Да', value: true },
          { title: 'Нет', value: false },
          { title: 'Наследуется от общей настройки', value: null },
        ]"
        v-model="item.courier_can_take_orders"
        item-text="title"
        item-value="value"
      ></v-select>
      <v-text-field
          dense
          outlined
          hide-details
          label="Телефон горячей линии"
          v-model="item.hotline_phone_number"
          class="mt-4"
      ></v-text-field>
      <v-text-field
          dense
          outlined
          hide-details
          label="Оплата курьеру за час"
          v-model="item.courier_reward_per_hour"
          class="mt-4"
          type="number"
      ></v-text-field>

    </v-col>
  </v-col>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";

export default {
  name: "OutletDeliverySettings",
  components: { ImageUploader },
  props: {
    item: Object,
  },
  emits: ['update']
};
</script>

<style scoped></style>
