import { render, staticRenderFns } from "./DisplayLine.vue?vue&type=template&id=83d43aba&scoped=true&"
import script from "./DisplayLine.vue?vue&type=script&lang=js&"
export * from "./DisplayLine.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83d43aba",
  null
  
)

export default component.exports