import { render, staticRenderFns } from "./UserRoleItemRules.vue?vue&type=template&id=ba5cf07c&scoped=true&"
import script from "./UserRoleItemRules.vue?vue&type=script&lang=js&"
export * from "./UserRoleItemRules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba5cf07c",
  null
  
)

export default component.exports