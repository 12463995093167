<template>
  <v-col class="pa-0">
    <v-row no-gutters>
      <v-col v-if="settings" class="mt-3" cols="5">
        <div class="text-h5 font-weight-bold">Общие настройки</div>
        <v-text-field
          v-model="settings.system_settings.app_promo_title"
          class="rounded-lg mt-5"
          dense
          hide-details
          label="Наименование промо блока в приложении"
          outlined
        ></v-text-field>
        <v-text-field
          v-model="settings.contact_settings.phone"
          class="rounded-lg mt-5"
          dense
          hide-details
          label="Телефон для обратной связи"
          outlined
        ></v-text-field>
        <div class="text-h5 font-weight-bold mt-5">Версия приложения</div>
        <v-text-field
          v-model.number="settings.required_builds.ios_required_build"
          class="rounded-lg mt-5"
          dense
          hide-details
          label="Мин. версия для ios"
          outlined
          type="number"
        ></v-text-field>
        <v-text-field
          v-model.number="settings.required_builds.android_required_build"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Мин. версия для android"
          outlined
          type="number"
        ></v-text-field>
        <div class="text-h5 font-weight-bold mt-5">Доступ к приложению</div>
        <v-checkbox
          v-model="settings.system_settings.app_enabled"
          class="ma-0 pa-0 my-4"
          hide-details
          label="Доступ к приложению"
        ></v-checkbox>
        <v-checkbox
          v-model="settings.system_settings.skip_accounting_orders"
          class="ma-0 pa-0 my-4"
          hide-details
          label="Не загружать заказы из 1С"
        ></v-checkbox>
        <div class="text-h5 font-weight-bold mt-5">Очередь</div>

        <v-text-field
          v-model.number="settings.system_settings.min_time_space_for_order"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Запас для расчета времени при формировании заказов в минутах"
          outlined
          type="number"
        ></v-text-field>
        <v-text-field
          v-model.number="settings.system_settings.helper_percent_boost"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Ускорение готовки с помощником %"
          outlined
          type="number"
        ></v-text-field>
        <v-text-field
          v-model.number="settings.system_settings.check_big_queue_every"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Проверять загруженность очереди каждые N (мин)"
          outlined
          type="number"
        ></v-text-field>
        <v-text-field
          v-model.number="settings.system_settings.big_queue_delay"
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Отправлять уведомление если очередь на точке больше чем N (мин)"
          outlined
          type="number"
        ></v-text-field>
<!--         <v-text-field-->
<!--          v-model.number="settings.system_settings.notify_about_decision_deadline"-->
<!--          class="rounded-lg mt-3"-->
<!--          dense-->
<!--          hide-details-->
<!--          label="Отправлять уведомление о необработанных отзывах за N (мин) до дедлайна"-->
<!--          outlined-->
<!--          type="number"-->
<!--        ></v-text-field>-->
        <v-text-field
          v-model.number="
            settings.system_settings.send_order_status_notification_delay
          "
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Макс. задержка смены статуса заказа для отправки уведомления (сек)"
          outlined
          type="number"
        ></v-text-field>
        <div class="text-h5 font-weight-bold mt-5">Дополнительно</div>

        <v-text-field
          v-model.number="
            settings.system_settings
              .dont_send_order_from_accounting_to_tracker_if_it_older_then
          "
          class="rounded-lg mt-3"
          dense
          hide-details
          label="Не отправлять заказ из 1С на трекер, если он старше N (мин)"
          outlined
          type="number"
        ></v-text-field>
        <div class="text-h5 font-weight-bold mt-5">Управление кешем меню</div>
        <v-btn
          :loading="clearMenuCacheLoading"
          class="mt-3"
          color="error"
          depressed
          @click="clearMenuCache()"
          >Очистить кеш меню
        </v-btn>
        <div class="text-h5 font-weight-bold mt-5">
          Уведомления о недоступной номенклатуре
        </div>
        <div
          v-for="(notification_time, index) in settings
            .disabled_nomenclature_notifications_settings.notification_times"
          :key="index"
          class="notification-time mt-5"
        >
          <v-text-field
            v-model="notification_time.value"
            class="rounded-lg"
            dense
            hide-details
            outlined
            style="max-width: 120px"
            type="time"
          />
          <v-checkbox v-model="notification_time.active" class="mt-0 ml-5" />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                style="margin-top: -4px"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark> mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list flat>
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title @click="deleteNotificationTime(index)"
                      >Удалить
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <div>
          <v-btn
            class="rounded-lg mt-5 text-none body-2"
            color="gray"
            depressed
            small
            @click="addNotificationTime()"
            >Добавить время +
          </v-btn>
        </div>
        <v-btn
          :disabled="!settings.system_settings.app_promo_title"
          :loading="loading"
          class="rounded-lg mt-8"
          color="primary"
          outlined
          @click="save"
          >Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";
import moment from "moment";
import TimePicker from "@/main/components/TimePicker.vue";
import { cloneDeep } from "lodash";

export default {
  name: "Settings",
  components: { TimePicker, ImageUploader },
  data: () => ({
    settings: null,
    loading: false,
    clearMenuCacheLoading: false,
  }),
  methods: {
    async save() {
      this.loading = true;
      try {
        const settings = cloneDeep(this.settings);
        const notificationSettings =
          settings.disabled_nomenclature_notifications_settings;
        notificationSettings.notification_times =
          notificationSettings.notification_times.map((v) => {
            console.log(this.getTime(v.value, "local"));
            return { ...v, value: this.getTime(v.value, "local") };
          });
        await this.$settings.sendPut("set_settings", settings);
        this.$store.commit("setSnackbar", {
          color: "success",
          text: "Сохранено",
        });
      } catch {
        this.$store.commit("setSnackbar", {
          color: "error",
          text: "Не удалось сохранить",
        });
      }
      this.loading = false;
    },
    getTime(v, from = "utc") {
      const format = "HH:mm";
      if (from === "utc") return moment.utc(v, format).local().format(format);
      else return moment(v, format).utc().format(format);
    },
    addNotificationTime() {
      this.settings.disabled_nomenclature_notifications_settings.notification_times.push(
        {
          value: "00:00",
          active: false,
        }
      );
    },
    deleteNotificationTime(index) {
      this.settings.disabled_nomenclature_notifications_settings.notification_times.splice(
        index,
        1
      );
    },
    clearMenuCache() {
      this.clearMenuCacheLoading = true;
      this.$product
        .sendPostSingle("clear_menu_cache")
        .then(() => {
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .finally(() => {
          this.clearMenuCacheLoading = false;
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "error",
            text: "Ошибка",
          });
        });
    },
  },
  mounted() {
    this.$settings.sendGet("current").then((res) => {
      const notificationSettings =
        res.disabled_nomenclature_notifications_settings;
      notificationSettings.notification_times =
        notificationSettings.notification_times.map((v) => {
          return { ...v, value: this.getTime(v.value) };
        });
      this.settings = res;
    });
  },
};
</script>

<style scoped>
.notification-time {
  //padding: 5px 10px; //border-radius: 8px; //border: 1px solid #a8a8a8; display: flex; align-items: center;
}

.notification-time :deep(.v-messages),
.notification-time :deep(.v-text-field__details) {
  display: none !important;
}
</style>
