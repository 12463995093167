<template>
    <v-container v-if="$store.getters.currentUser">
        <v-row no-gutters align="center">
            <div class="text-h6 font-weight-regular" v-text="formatPhone($store.getters.currentUser.phone)"/>
            <v-spacer/>
            <v-btn icon color="primary" @click="logout">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-row>

        <v-text-field
            placeholder="Имя" hide-details prepend-inner-icon="mdi-draw"
            :append-icon="username && username !== $store.getters.currentUser.first_name ? 'mdi-content-save' : ''"
            @click:append="saveName"
            solo flat background-color="grey lighten-3" class="rounded-lg body-2 my-4"
            v-model="username"></v-text-field>

        <v-text-field
            placeholder="Email" hide-details prepend-inner-icon="mdi-at"
            :append-icon="email && email !== $store.getters.currentUser.email ? 'mdi-content-save' : ''"
            @click:append="saveEmail"
            solo flat background-color="grey lighten-3" class="rounded-lg body-2 mb-2" v-model="email"></v-text-field>
        <div class="caption mb-5 ml-1 grey--text text--darken-2">Если хотите получать чеки, укажите Вашу почту</div>
        <v-row dense>
            <v-col cols="6">
                <v-card class="rounded-lg pa-3" color="primary" height="60" flat :to="{name: 'orders'}">
                    <div class="d-flex align-center justify-center fill-height">
                        <div class="text-body-2 text-center white--text text-uppercase">
                            История заказов
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="rounded-lg pa-3" color="primary" height="60" flat :to="{name: 'paymentInfo'}">
                    <div class="d-flex align-center justify-center fill-height">
                        <div class="text-body-2 text-center white--text text-uppercase">
                            Условия использования
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-3" align="center" justify="center">
            <div class="text-caption">Подписывайтесь на наши социальные сети</div>
        </v-row>
        <v-row no-gutters class="mt-1" align="center" justify="center">
            <a href="https://vk.com/rosterhit" target="_blank">
                <img src="/media/vk.png" alt="instagram" height="30px" width="30px">
            </a>
<!--            <div class="mx-2"/>-->
<!--            <a href="https://instagram.com/rosterhit" target="_blank">-->
<!--                <img src="/media/instagram.png" alt="instagram" height="30px" width="30px">-->
<!--            </a>-->
        </v-row>
    </v-container>
    <phone-login v-else/>
</template>

<script>
import OrderList from "@/main/components/order/OrderList";
import PhoneLogin from "@/main/views/auth/PhoneLogin";

export default {
    name: "Account",

    components: {
        OrderList,
        PhoneLogin,
    },

    data: () => ({
        changingName: false,
        changingEmail: false,
        username: null,
        email: null,
        nameLoading: false,
        emailLoading: false,
    }),

    methods: {
        logout() {
            this.$router.push({name: "home"})
            this.$store.dispatch("logout")
        },

        changeName() {
            this.username = this.$store.getters.currentUser.first_name;
            this.changingName = true;
        },
        changeEmail() {
            this.email = this.$store.getters.currentUser.email;
            this.changingEmail = true;
        },

        async saveName() {
            this.nameLoading = true;
            const response = await axios.put("/users/change_first_name/", {
                first_name: this.username,
            });
            this.$store.state.auth.user.first_name = this.username;
            this.changingName = false;
            this.nameLoading = false;
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Имя сохранено",
            })
        },
        async saveEmail() {
            axios.put("/users/change_email/", {
                email: this.email,
            }).then(() => {
                this.$store.state.auth.user.email = this.email;
                this.$store.commit("setSnackbar", {
                    color: "success",
                    text: "Почта сохранена",
                });
            }).catch(() => {
                this.$store.commit("setSnackbar", {
                    color: "error",
                    text: "Неверная почта",
                });
            })
        },

        formatPhone(phone) {
            const countryCode = phone[0]
            const operatorCode = phone.substring(1, 4)
            const firstPart = phone.substring(4, 7)
            const secondPart = phone.substring(7, 9)
            const thirdPart = phone.substring(9, 11)
            return `+${countryCode} (${operatorCode}) ${firstPart}-${secondPart}-${thirdPart}`
        },
    },
    created() {
        if (this.$store.getters.currentUser) {
            this.email = this.$store.getters.currentUser.email
            this.username = this.$store.getters.currentUser.first_name
        }
    }
}
</script>
