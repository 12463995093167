<template>
    <v-overlay
            :z-index="100"
            :value="loading"
            opacity="0.8"
    >
        <div class="parent" style="height: 200px; width: 200px;">
            <v-img class="loading" id="loading" src="/static/logo-round-text.png"/>
            <v-img class="center" src="/static/logo-fox.png"/>
        </div>
        <!--        <v-img height="150" width="150" src="/media/logo-round-text.png">-->
        <!--            <v-row no-gutters align="center" justify="center">-->
        <!--                <v-img height="140" width="140" src="/media/logo-fox.png"/>-->
        <!--            </v-row>-->
        <!--        </v-img>-->
    </v-overlay>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Loader",

        computed: {
            ...mapState({
                loading: state => state.loading
            })
        }
    }
</script>

<style scoped>
    .parent {
        position: relative;
    }

    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200px;
        height: 200px;
        margin: -100px 0 0 -100px;
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
        animation: spin 10s linear infinite;
    }

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }

    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>