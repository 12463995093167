<template>
  <v-col class="pa-0">
    <v-row no-gutters>
      <v-col v-if="settings" class="mt-3" cols="5">
        <div class="text-h5 font-weight-bold">Настройки доставки</div>
        <v-text-field
            v-model="settings.delivery_settings.courier_reward_per_hour"
            class="rounded-lg mt-3"
            dense
            hide-details
            label="Оплата курьеру за час"
            type="number"
            outlined
        ></v-text-field>
        <v-select
            :items="notifyIfAvailableItems"
            v-model="settings.delivery_settings.notify_if_available"
            class="rounded-lg mt-3"
            item-value="value"
            item-text="label"
            dense
            hide-details
            label="Отображение окна о доступности доставки"
            type="number"
            outlined
        ></v-select>
        <v-checkbox
            v-model="settings.system_settings.delivery_enabled"
            class="ma-0 pa-0 my-4"
            hide-details
            label="Доставка включена"
        ></v-checkbox>
        <v-checkbox class="ma-0 pa-0 my-4" label="Доставка доступна только для гостей, которые находятся в активной зоне доставки"
                    v-model="settings.system_settings.location_depended_delivery" hide-details/>
        <v-checkbox label="Курьер может брать заказы самостоятельно"
                    v-model="settings.delivery_settings.courier_can_take_orders"/>
        <v-btn
            :loading="loading"
            class="rounded-lg mt-0"
            color="primary"
            outlined
            @click="save"
        >Сохранить
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

export default {
  name: 'Settings',
  components: {},
  data: () => ({
    settings: null,
    loading: false,
    notifyIfAvailableItems: [
      {
        label: 'Никогда',
        value: 'never'
      },
      {
        label: '1 раз',
        value: 'once'
      },
      {
        label: 'Всегда',
        value: 'always'
      },
    ]
  }),
  methods: {
    async save() {
      this.loading = true
      const deliverySettings = this.settings.delivery_settings || {}
      const deliverySettingsKeys = Object.keys(deliverySettings)
      for (const key of deliverySettingsKeys) {
        const invalidValues = ['', null]
        const value = deliverySettings[key]
        if (invalidValues.includes(value)) deliverySettings[key] = 0
      }
      if (this.settings.delivery_settings.courier_reward_per_hour === '')
        this.settings.system_settings.courier_reward_per_hour = null
      try {
        await this.$settings.sendPut('set_settings', this.settings)
        this.$store.commit('setSnackbar', {
          color: 'success',
          text: 'Сохранено'
        })
      } catch {
        this.$store.commit('setSnackbar', {
          color: 'error',
          text: 'Не удалось сохранить'
        })
      }
      this.loading = false
    }
  },
  mounted() {
    this.$settings.sendGet('current').then((res) => {
      this.settings = res
    })
  }
}
</script>

<style scoped></style>