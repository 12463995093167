const m = require('air-vue-model')()
const airLeaflet = require('air-vue-leaflet')()
m.url = 'outlets'
m.name = 'outlet'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Торговая точка', listTitle: 'Торговые точки'
}

m.generateMapLayer = (outlets, grey = false, clickFunction = null,) => {
    const points = []
    for (const outlet of outlets) {
        const {id, coords, closest_time} = outlet
        points.push({id, coords: [coords[1], coords[0]], closest_time})
    }
    const pointCollection = airLeaflet.pointCollection(points, (val) => {
        return {pt: val.closest_time}
    })
    return L.geoJson(pointCollection, {
        onEachFeature: (feature, layer) => {
            if (clickFunction) {
                layer.on('click', () => clickFunction)
            }
        }, pointToLayer: (feature, latlng) => {
            const pt = feature.properties.pt
            let draw_icon
            if (!pt) {
                draw_icon = new L.icon({
                    iconUrl: grey ? '/static/logo-short-grey.png' : '/static/logo-short.png', iconSize: [20, 28],
                })
            } else {
                const now = moment().local()
                const currentPt = moment.utc(pt.pt).local()
                const diff = currentPt.diff(now, 'minute') + 1
                draw_icon = new L.divIcon({
                    className: 'custom-div-icon', html: `<div class="map-circle green white--text">
                                        <div class="font-weight-medium" style="height: 13px">${diff}</div>
                                   <div>мин</div></div>`, iconSize: [40, 40], iconAnchor: [15, 42]
                })
            }
            return L.marker(latlng, {
                icon: draw_icon
            })
        },
    })
}

m.routes = [{
    name: 'list', component: require('@/admin/views/outlets/Outlets'),
}, {
    name: 'item', component: require('@/admin/views/outlets/Outlet'), single: true,
},]

module.exports = m