<template>
    <v-col class="pa-0">
        <v-data-table
            @click:row="openDialog($event.id)"
            :headers="headers"
            :items="items"
            :items-per-page="999"
            hide-default-footer
        >
            <template v-slot:item.outlet="{ item }">
                <div>{{ item.outlet.number }}. {{ item.outlet.address }}</div>
            </template>
            <template v-slot:item.created_at="{ item }">
                <div>{{ formatCreatedAt(item.created_at) }}</div>
            </template>
          <template v-slot:item.review="{ item }">
            <div :class="{
              'red--text font-weight-bold': item.review && item.review.rating < 3
            }">{{ item.review ? item.review.rating + 1 : '' }}</div>
          </template>
          <template v-slot:item.review_comment="{ item }">
            <div>{{ item.review && item.review.comment ?  'Да' : '' }}</div>
          </template>
            <template v-slot:item.start_cooking_time="{ item }">
                <div>{{ formatToHours(item.start_cooking_time) }}</div>
            </template>
            <template v-slot:item.start_diff="{ item }">
                <div :class="getDiff(item.start_cooking_time, item.processed_at).color">
                    {{ getDiff(item.start_cooking_time, item.processed_at).text }}
                </div>
            </template>
            <template v-slot:item.end_diff="{ item }">
                <div :class="getDiff(item.end_cooking_time, item.ready_at).color">
                    {{ getDiff(item.end_cooking_time, item.ready_at).text }}
                </div>
            </template>
            <template v-slot:item.end_cooking_time="{ item }">
                <div>{{ formatToHours(item.end_cooking_time) }}</div>
            </template>
            <template v-slot:item.ready_at="{ item }">
                <div>{{ formatToHours(item.ready_at) }}</div>
            </template>
            <template v-slot:item.processed_at="{ item }">
                <div>{{ formatToHours(item.processed_at) }}</div>
            </template>
            <template v-slot:item.released_at="{ item }">
                <div>{{ formatToHours(item.released_at) }}</div>
            </template>
          <template v-slot:item.source="{ item }">
            <div>{{ $order.sourceName[item.source] }}</div>
          </template>
            <template v-slot:item.user_status="{ item }">
                <OrderStatus :status="item.user_status"></OrderStatus>
            </template>
            <template v-slot:item.sum="{ item }">
                <v-row no-gutters class="flex-nowrap">
                    <div class="font-weight-bold mr-1">{{ item.items.length }}</div>
                    <v-icon size="15" class="mr-2">mdi-cube-outline</v-icon>
                    <div class="font-weight-bold mr-1">{{ item.sum }}</div>
                    <v-icon size="15">mdi-currency-rub</v-icon>

                    <OrderBalanceView class="ml-1" :object="item"></OrderBalanceView>

                </v-row>
            </template>
            <template v-slot:item.info="{ item }">
                <v-row no-gutters class="flex-nowrap">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="width: 20px; height: 20px" v-on="on">
                      <v-icon  size="20" :color="item.as_soon_as_possible === false ? 'green darken-1' : 'grey lighten-2'">
                        mdi-clock-time-eight
                      </v-icon>
                      </div>
                    </template>
                    <div>{{
                        item.as_soon_as_possible === false ? `Пользователь выбрал ${parseDate(item.preferred_time)}` : 'Как можно скорее'
                      }}</div>
                  </v-tooltip>

                    <v-icon class="ml-2" size="20" :color="item.paid_at ? 'green darken-1' : 'grey lighten-2'">
                        mdi-cash-100
                    </v-icon>
                    <v-icon size="17" class="ml-2"
                            :color="!item.vendor_code ? 'green darken-1' : 'grey lighten-2'">
                        mdi-monitor-cellphone
                    </v-icon>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="width: 20px; height: 20px" v-on="item.vendor_code && item.user?.phone !== 'api_integration' ? on : undefined">
                        <v-icon class="ml-2" size="20" :color="item.vendor_code && item.user?.phone !== 'api_integration' ? 'green darken-1' : 'grey lighten-2'">mdi-home-account</v-icon>
                      </div>
                    </template>
                    <div>Заказ сделан пользователем на кассе ТТ</div>
                  </v-tooltip>

                </v-row>
            </template>

        </v-data-table>
        <order-view-dialog v-model="dialog"></order-view-dialog>
    </v-col>

</template>

<script>
import OrderStatus from "@/admin/views/orders/OrderStatus.vue";
import moment from "moment";
import OrderBalanceView from "@/admin/views/orders/OrderBalanceView.vue";
import OrderViewDialog from "@/admin/views/equipmentLockedPeriod/OrderViewDialog.vue";

export default {
    name: 'OrdersTable',
    props: {
        items: Array,
        hideColumns: Array
    },
    components: {OrderViewDialog, OrderBalanceView, OrderStatus},
    data: () => ({
        dialog: false
    }),
    computed: {
        headers() {
            const items = [
                {
                    text: 'Инфо',
                    align: 'center',
                    sortable: false,
                    value: 'info'
                },
                {
                    text: 'Номер',
                    align: 'center',
                    sortable: false,
                    value: 'daily_number',
                },
                {
                    text: 'Статус',
                    align: 'center',
                    sortable: false,
                    value: 'user_status',
                },
              {
                text: 'Источник',
                align: 'center',
                sortable: false,
                value: 'source',
              },
              {
                text: 'Оценка',
                align: 'center',
                sortable: false,
                value: 'review',
              },
              {
                text: 'Отзыв',
                align: 'center',
                sortable: false,
                value: 'review_comment',
              },
                {
                    text: 'Создан',
                    align: 'center',
                    sortable: false,
                    value: 'created_at',
                },
                {
                    text: 'Пользователь',
                    align: 'center',
                    sortable: false,
                    value: 'user.phone',
                },
                {
                    text: 'Точка',
                    align: 'start',
                    sortable: false,
                    value: 'outlet'
                },
                {
                    text: 'Начало готовки',
                    align: 'center',
                    sortable: false,
                    value: 'start_cooking_time'
                },
                {
                    text: 'Факт',
                    align: 'center',
                    sortable: false,
                    value: 'processed_at'
                },
                {
                    text: 'Разница',
                    align: 'center',
                    sortable: false,
                    value: 'start_diff'
                },
                {
                    text: 'Конец готовки',
                    align: 'center',
                    sortable: false,
                    value: 'end_cooking_time'
                },
                {
                    text: 'Факт',
                    align: 'center',
                    sortable: false,
                    value: 'ready_at'
                },
                {
                    text: 'Разница',
                    align: 'center',
                    sortable: false,
                    value: 'end_diff'
                },
                {
                    text: 'Выдан',
                    align: 'center',
                    sortable: false,
                    value: 'released_at'
                },
                {
                    text: 'Сумма',
                    align: 'center',
                    sortable: false,
                    value: 'sum'
                }
            ]
            if (!this.hideColumns) return items
            return items.filter(v => !this.hideColumns.includes(v.value))
        }
    },
    methods: {
        openDialog(id) {
            console.log(id)
            this.$order.loadItem(id).then(() => {
                this.dialog = true
            })
        },
        getDiff(start, end) {
            if (!start || !end) return {text: '-', color: 'text--black'}
            const endDate = moment(end)
            const startDate = moment(start)
            let diffInMinutes = endDate.diff(startDate, 'minutes') * -1;
            let fail = false
            if (diffInMinutes < 0) {
                fail = true
            }
            return {
                text: diffInMinutes,
                color: fail ? 'red--text font-weight-bold' : 'text--black'
            }
        },
        formatToHours(value) {
            if (!value) return '-'
            return moment.utc(value).local().format('HH:mm')
        },
        formatCreatedAt(value) {
            if (!value) return '-'
            return moment.utc(value).local().format('DD.MM.YYYY HH:mm')
        },
      parseDate(date) {
        return window.moment.localDateTime(date);
      },
    }
}
</script>

<style scoped>

</style>