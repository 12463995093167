const m = require('air-vue-model')()
const ingredientGroup = require('./ingredientGroup')
const productIngredientGroupIngredient = require('./productIngredientGroupIngredient')
const productIngredientGroup = require('./productIngredientGroup')
const ingredient = require('./ingredient')
m.url = 'products'
m.name = 'product'
m.setPagination({
    page_size: 'all'
})
m.text = {
    itemTitle: 'Товар',
    listTitle: 'Товары'
}
m.routes = [
    {
        name: 'item',
        component: require('@/admin/views/products/ProductItem'),
        redirect: {
            name: 'productBase'
        },
        single: true,
        children: [
            {name: 'base', path: 'base', component: require('@/admin/views/products/ProductItemBase.vue')},
            {
                name: 'ingredients',
                path: 'ingredients',
                component: require('@/admin/views/products/ProductItemIngredients.vue'),
                children: [
                    {
                        name: "productIngredientGroup",
                        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroup.vue"),
                        single: true,
                        model: productIngredientGroup,
                        redirect: {name: 'productProductIngredientGroupItem'},
                        children: [
                            {
                                path: '',
                                name: "productIngredientGroupItem",
                                component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupItem.vue"),
                                model: productIngredientGroup,
                            },
                            {
                                path: 'outlets',
                                name: "productIngredientGroupOutlets",
                                component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupOutletItems.vue"),
                                model: productIngredientGroup,
                            },
                            {
                                name: 'ingredientGroup',
                                component: require('@/admin/views/ingredientGroups/IngredientGroupItem'),
                                single: true,
                                model: ingredientGroup,
                            },
                        ],
                    },
                    {
                        name: "productIngredient",
                        component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredient.vue"),
                        single: true,
                        model: productIngredientGroupIngredient,
                        redirect: {name: 'productProductIngredientItem'},
                        children: [
                            {
                                path: '',
                                name: "productIngredientItem",
                                component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientItem"),
                            },
                            {
                                path: 'outlets',
                                name: "productIngredientOutlets",
                                component: require("@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupIngredientOutletItems"),
                            },
                            {
                                name: 'ingredient',
                                component: require('@/admin/views/ingredients/IngredientItem'),
                                single: true,
                                model: ingredient,

                                children: [
                                    {
                                        path: '',
                                        name: 'ingredientBase',
                                        component: require('@/admin/views/ingredients/IngredientItemBase.vue')
                                    },
                                    {
                                        path: 'prices',
                                        name: 'ingredientPrices',
                                        component: require('@/admin/views/ingredients/IngredientItemPrices.vue')
                                    },
                                    {
                                        path: 'nutritions',
                                        name: 'ingredientNutritional',
                                        component: require('@/admin/views/ingredients/IngredientItemNutritional.vue')
                                    },
                                    {
                                        path: 'nomenclature',
                                        name: 'ingredientNomenclature',
                                        component: require('@/admin/views/ingredients/IngredientItemNomenclature.vue')
                                    },
                                ]
                            }
                        ],
                    },
                ]
            },
            {
                name: 'outlets',
                path: 'outlets',
                component: require('@/admin/views/products/ProductItemOutlets.vue')
            },
        ]
    }
]

module.exports = m