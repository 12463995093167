var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"auto"}},[_c('template-list',{attrs:{"auto-load-list":false,"model":_vm.$reason,"show-create-button":false},scopedSlots:_vm._u([(_vm.$auth.hasRight('reason__edit_information'))?{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":"","height":"40"},on:{"click":function($event){return _vm.openModal()}}},[_c('div',{staticClass:"text-none body-2 px-2"},[_vm._v("Создать")])])]},proxy:true}:null,{key:"subactions",fn:function(){return _vm._l((_vm.$reason.types),function(el,index){return _c('v-btn',{key:index,staticClass:"text-none body-2 mr-3",attrs:{"color":el.value === _vm.$reason.filters.type ? 'primary' : 'grey',"outlined":"","small":""},on:{"click":function($event){(_vm.$reason.filters.type = el.value), _vm.loadList()}}},[_vm._v(_vm._s(el.label)+" ")])})},proxy:true},{key:"default",fn:function({ object, index }){return [_c('v-col',{staticClass:"pa-0 link",on:{"click":function($event){_vm.$auth.hasRight('reason__edit_information')
            ? _vm.openModal(object)
            : void 0}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"ml-2 font-weight-medium text-truncate"},[_vm._v(" "+_vm._s(object.text)+" "),(
                (typeof object.type === 'number' && object.type === 1) ||
                object.type.value === 1
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"color":object.include_in_disabled_nomenclature_analytics
                        ? 'success'
                        : 'error',"size":"18px"}},[_vm._v(_vm._s(object.include_in_disabled_nomenclature_analytics ? "check" : "close"))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(object.include_in_disabled_nomenclature_analytics ? "Учитывается" : "Не учитывается")+" в отчете о заблокированной номенклатуре")])]):_vm._e()],1)])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialogItem)?_c('v-card',{staticClass:"pa-5 rounded-lg",staticStyle:{"overflow-x":"auto"}},[_c('div',{staticClass:"font-weight-bold mb-5"},[_vm._v(" "+_vm._s(_vm.dialogItem.id ? "Обновление причины" : "Создание причины")+" ")]),_c('v-select',{attrs:{"items":_vm.$reason.types,"item-text":"label","item-value":"value","label":"Тип"},model:{value:(_vm.dialogItem.type),callback:function ($$v) {_vm.$set(_vm.dialogItem, "type", $$v)},expression:"dialogItem.type"}}),_c('v-textarea',{attrs:{"label":"Текст"},model:{value:(_vm.dialogItem.text),callback:function ($$v) {_vm.$set(_vm.dialogItem, "text", $$v)},expression:"dialogItem.text"}}),(
          (typeof _vm.dialogItem.type === 'number' && _vm.dialogItem.type === 1) ||
          _vm.dialogItem.type.value === 1
        )?_c('v-checkbox',{attrs:{"label":"Учитывать в отчете о заблокированной номенклатуре"},model:{value:(_vm.dialogItem.include_in_disabled_nomenclature_analytics),callback:function ($$v) {_vm.$set(_vm.dialogItem, "include_in_disabled_nomenclature_analytics", $$v)},expression:"dialogItem.include_in_disabled_nomenclature_analytics"}}):_vm._e(),_c('v-row',{staticClass:"px-2 mt-0"},[_c('v-btn',{attrs:{"disabled":!_vm.dialogItem.type || !_vm.dialogItem.text,"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.saveDialogItem()}}},[_vm._v("Сохранить ")]),_c('v-spacer'),(_vm.dialogItem.id)?_c('v-btn',{attrs:{"loading":_vm.loading,"color":"error","outlined":""},on:{"click":function($event){return _vm.deleteDialogItem()}}},[_vm._v("Удалить ")]):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }