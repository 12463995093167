<template>
  <div>
    <v-row align="center" class="px-4 mb-6 my-0">
      <div class="text-h6 mr-3">Основная информация</div>
      <v-divider />
      <v-btn
        v-if="$auth.hasRight('products__edit_information')"
        :loading="saveLoading"
        height="40"
        depressed
        class="rounded-lg ml-3"
        color="primary"
        @click="save()"
      >
        <div class="text-none body-2 px-2">Сохранить</div>
      </v-btn>
    </v-row>
    <div class="text-h5">{{ $productIngredientGroupIngredient.item.name }}</div>
    <v-text-field
      v-model="$productIngredientGroupIngredient.item.sorting"
      type="number"
      outlined
      hide-details
      dense
      label="Сортировка"
      class="my-3"
    />
    <v-text-field
      @input="
        $productIngredientGroupIngredient.item.promo_text = !$event
          ? null
          : $event
      "
      v-model="$productIngredientGroupIngredient.item.promo_text"
      outlined
      hide-details
      dense
      label="Промо текст"
      class="my-3"
    />
  </div>
</template>

<script>
import {cloneDeep, isEqual} from "lodash";

export default {
  name: "ProductGroupItem",
  data: () => {
    return {
      saveLoading: false,
      initialItem: null,
      eventsToClear: [],
    }
  },
  methods: {
    cloneDeep,
    isEqual,
    save() {
      this.saveLoading = true
      this.$productIngredientGroupIngredient.update(this.$productIngredientGroupIngredient.item).then(() => {
        this.$store.commit('setSnackbar', {color: 'success', text: 'Сохранено'})
      }).catch(err => {
        this.$store.commit('setSnackbar', {color: 'fail', text: 'Не удалось сохранить'})
      }).finally(() => {
        this.saveLoading = false
      })
    }
  },
  mounted() {
    this.initialItem = cloneDeep(this.$productIngredientGroupIngredient.item)
    this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
      if (!isEqual(cloneDeep(this.$productIngredientGroupIngredient.item), this.initialItem)) {
        const answer = window.confirm(
            "Если вы уйдете со страницы, то потеряете все несохраненные данные"
        );
        if (answer) {
          if (this.eventsToClear.length) this.eventsToClear.map(v => v());
          next();
        } else {
          this.$store.commit('setProductDrawer', true)
        }
      } else next()
    }))
    window.onbeforeunload = () => {
      if (!isEqual(this.initialItem, this.$productIngredientGroupIngredient.item)) {
        return confirm()
      }
    }
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },
  computed: {
    title() {
      const values = [];
      if (this.$product.item) {
        if (this.$product.item.group) {
          values.push(this.$product.item.group.vendor_name || "Отсутствует");
        }
        values.push(this.$product.item.vendor_name || "Отсутствует");
      }
      values.push(
        this.$productIngredientGroupIngredient.item.ingredient.vendor_name ||
          "Отсутствует"
      );
      return values.join(" / ");
    },
  },
};
</script>
