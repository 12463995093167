<template>
    <v-dialog v-model="$outlet.dialog" max-width="500" :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card v-if="outlet">
            <v-row no-gutters class="pa-2">
                <v-btn icon @click="$outlet.dialog = false">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </v-row>
            <v-row no-gutters class="pa-3" align="center" justify="center">
                <v-img height="200" cover :src="image" class="rounded-lg"/>
            </v-row>
            <div class="text-h5 text-md-h4 px-2 mb-3" v-text="outlet.address"/>
            <div class="text-caption text--secondary px-2">Режим работы:</div>
            <div class="text-body-2 px-2" v-for="(schedule, index) in schedules" :key="index" v-text="schedule"/>
            <outlet-limited-products class="px-2" :limited-products="limitedProducts"/>
            <div v-if="outlet.description" class="text-body-2 px-2 my-3" v-text="outlet.description"/>
            <v-row no-gutters align="center" justify="center" class="pa-3"
                   :class="{'fixed-to-bottom': $vuetify.breakpoint.smAndDown}">
                <v-btn block class="rounded-lg" :disabled="isSelected || (cartItems.length && !outlet.closest_time)"
                       color="primary" :loading="loading"
                       height="50" width="300" depressed @click="select">
                    <div class="text-none text-body-2">{{ isSelected ? 'Торговая точка выбрана' : 'Заказать здесь' }}
                    </div>
                </v-btn>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import datetime from "../../vendor/datetime";
import OutletLimitedProducts from "../components/OutletLimitedProducts";

export default {
    name: "OutletModal",
    components: {
        OutletLimitedProducts
    },
    mixins: [datetime],
    data: () => ({
        dialog: false,
        loading: false,
    }),
    methods: {
        select() {
            this.$store.commit('setOutlet', this.outlet)
            this.$outlet.dialog = false
        },

        getShrinkedScheduleText() {
            const schedules = []

            let from = null
            let to = null
            let times = null

            for (const schedule of this.outlet.schedules) {
                const scheduleTimes = []
                const workDayName = this.formatWorkDay(schedule.work_day)

                for (const time of schedule.times) {
                    const timeFrom = this.getCurrentTime(time.time_from)
                    const timeTo = this.getCurrentTime(time.time_to)
                    // const timeFrom = time.time_from.substring(0, 5)
                    // const timeTo = time.time_to.substring(0, 5)
                    scheduleTimes.push(`${timeFrom} - ${timeTo}`)
                }
                const scheduleTimesText = scheduleTimes.join(', ')

                if (!times) {
                    from = workDayName
                    times = scheduleTimesText
                }

                if (scheduleTimesText !== times) {
                    this.pushShrinkedScheduleText(schedules, to, from, times)
                    from = workDayName
                    times = scheduleTimesText
                }

                to = workDayName
            }
            this.pushShrinkedScheduleText(schedules, to, from, times)

            return schedules
        },
        getCurrentTime(time) {
            return moment.utc(time, 'H:m:s').local().format('HH:mm:ss').substring(0, 5)
        },

        pushShrinkedScheduleText(schedules, to, from, times) {
            let workDays = ''
            if (from === to) {
                workDays = from
            } else if (from === 'пн' && to === 'вс') {
                workDays = 'Ежедневно'
            } else {
                workDays = `${from} - ${to}`
            }

            schedules.push(`${workDays} ${times}`)
        },

        async loadList() {
            this.loading = true
            await this.$outlet.loadList({
                cart_items: this.$store.state.cartItems,
            })
            this.loading = false
        },
    },
    computed: {
        cartItems() {
            return this.$cart.getCartItemIds(this.$store.state.cartItems)
        },
        outlet() {
            return this.$outlet.item
        },

        isSelected() {
            return this.$store.state.selectedOutletId === this.outlet.id
        },

        image() {
            if (this.outlet.images.length === 0) return '/static/not-found.png'
            return this.outlet.images[0].image
        },

        schedules() {
            return this.getShrinkedScheduleText()
        },

        limitedProducts() {
            const listOutlet = this.$outlet.list.find(e => e.id === this.outlet.id)
            if (!listOutlet) return []
            const limProducts = []
            for (const limitedProduct of listOutlet.limited_products) {
                limProducts.push(this.$productGroup.findProduct(limitedProduct.product))
            }
            return limProducts
        },
    }
}
</script>

<style scoped>
.fixed-to-bottom {
    position: absolute;
    bottom: 0;
}
</style>
