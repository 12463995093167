import '../bootstrap'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '../plugins/vuetify'
import store from "./store/store"

Vue.config.productionTip = false
Vue.prototype.$auth = Vue.observable(require('../airModels/auth'))
Vue.prototype.$rules = Vue.observable(require('air-vue-model/rules'))

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
