import Vue from "vue"
import App from "./App.vue"
import '../bootstrap'
import './models'
import router from './router/router'
import store from "./store/store"
import vuetify from "../plugins/vuetify"
import 'vue-json-viewer/style.css'
import 'air-vue-leaflet/dist/air-vue-leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import {initDefaultComponents} from '@/vendor/initApp'
import VueApexCharts from 'vue-apexcharts'
import drawLocales from 'leaflet-draw-locales'
import JsonViewer from 'vue-json-viewer'

Vue.use(JsonViewer)
Vue.use(VueApexCharts)

initDefaultComponents(Vue)
Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.prototype.$L = require('air-vue-leaflet')()
const leafletDraw = require('leaflet-draw');
drawLocales('ru')
const heat = require('leaflet.heat')
Vue.prototype.$eventBus = new Vue()

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app")
