<template>
  <div>
    <template-item
      v-show="false"
      :model="$ingredientGroup"
      :show-delete="false"
      v-slot="{ object, rules }"
      :after-save="afterSave"
      ref="templateItemRef"
    >
    </template-item>
    <v-row align="center" class="px-4 mb-6 my-0">
      <div class="text-h6 mr-3">Основная информация</div>
      <v-divider />
      <v-btn
        v-if="$auth.hasRight('products__edit_information')"
        height="40"
        depressed
        class="rounded-lg ml-3"
        color="primary"
        @click="save()"
      >
        <div class="text-none body-2 px-2">Сохранить</div>
      </v-btn>
    </v-row>

    <div>
      Наименование из учетной системы:
      <span class="font-weight-bold">{{
        $ingredientGroup.item.vendor_name || "Отсутствует"
      }}</span>
    </div>
    <v-text-field
      v-model="$ingredientGroup.item.name"
      outlined
      hide-details
      dense
      label="Клиентское наименование"
      class="mt-4"
    />
  </div>
</template>

<script>
import ImageUploader from "@/admin/components/ImageUploader.vue";
import DiscountSystemInformation from "@/admin/views/discountSystems/DiscountSystemInformation.vue";
import ProductIngredientGroupTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupTabs.vue";
import {cloneDeep, isEqual} from "lodash";

export default {
  name: "IngredientGroupItem",
  components: {
    ProductIngredientGroupTabs,
    DiscountSystemInformation,
    ImageUploader,
  },
  data: () => {
    return {
      initialItem: null,
      eventsToClear: [],
    }
  },
  computed: {},
  methods: {
    save() {
      this.$refs.templateItemRef.save();
    },
    afterSave() {
      this.initialItem = cloneDeep(this.$ingredientGroup.item)
    }
  },
  mounted() {
    this.initialItem = cloneDeep(this.$ingredientGroup.item)
    this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
      if (!isEqual(this.initialItem, this.$ingredientGroup.item)) {
        const answer = window.confirm(
            "Если вы уйдете со страницы, то потеряете все несохраненные данные"
        );
        if (answer) {
          if (this.eventsToClear.length) this.eventsToClear.map(v => v());
          next();
        } else {
          this.$store.commit('setProductDrawer', true)
        }
      } else next()
    }))
    window.onbeforeunload = () => {
      if (!isEqual(this.initialItem, this.$ingredientGroup.item)) {
        return confirm()
      }
    }
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },
};
</script>
