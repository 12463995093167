<template>
    <v-container>
        <v-row no-gutters class="mb-10" align="center" justify="center">
            <v-img max-width="60%" contain :src="image" class="rounded"/>
        </v-row>
        <div class="text-h4 mb-3" v-text="outlet.address"/>
        <div class="text-caption text--secondary">Режим работы:</div>
        <div class="text-body-2" v-for="(schedule, index) in schedules" :key="index" v-text="schedule"/>
        <outlet-limited-products :limited-products="limitedProducts"/>
        <div v-if="outlet.description" class="text-body-2 my-3" v-text="outlet.description"/>
        <v-row no-gutters align="center" justify="center">
            <v-btn :disabled="isSelected" color="primary" :loading="loading"
                   height="50" width="300" depressed @click="select">
                <div class="text-none text-body-2">{{ isSelected ? 'Торговая точка выбрана' : 'Заказать здесь' }}</div>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import datetime from "../../vendor/datetime";
import OutletLimitedProducts from "../components/OutletLimitedProducts";

export default {
    name: "Outlet",

    mixins: [datetime],

    components: {
        OutletLimitedProducts,
    },

    data: () => ({
        loading: false,
    }),

    computed: {
        outlet() {
            return this.$outlet.item
        },

        isSelected() {
            return this.$store.state.selectedOutletId === this.outlet.id
        },

        image() {
            if (this.outlet.images.length === 0) return '/static/not-found.png'
            return this.outlet.images[0].image
        },

        schedules() {
            return this.getShrinkedScheduleText()
        },

        limitedProducts() {
            const listOutlet = this.$outlet.list.find(e => e.id === this.outlet.id)
            if (!listOutlet) return []
            const limProducts = []
            for (const limitedProduct of listOutlet.limited_products) {
                limProducts.push(this.$productGroup.findProduct(limitedProduct.product))
            }
            return limProducts
        },
    },

    methods: {
        select() {
            this.$store.state.selectedOutletId = this.outlet.id
            this.$router.push({name: 'outlets'})
        },

        getShrinkedScheduleText() {
            const schedules = []

            let from = null
            let to = null
            let times = null

            for (const schedule of this.outlet.schedules) {
                const scheduleTimes = []
                const workDayName = this.formatWorkDay(schedule.work_day)

                for (const time of schedule.times) {
                    const timeFrom = time.time_from.substring(0, 5)
                    const timeTo = time.time_to.substring(0, 5)
                    scheduleTimes.push(`${timeFrom} - ${timeTo}`)
                }
                const scheduleTimesText = scheduleTimes.join(', ')

                if (!times) {
                    from = workDayName
                    times = scheduleTimesText
                }

                if (scheduleTimesText !== times) {
                    this.pushShrinkedScheduleText(schedules, to, from, times)
                    from = workDayName
                    times = scheduleTimesText
                }

                to = workDayName
            }
            this.pushShrinkedScheduleText(schedules, to, from, times)

            return schedules
        },

        pushShrinkedScheduleText(schedules, to, from, times) {
            let workDays = ''
            if (from === to) {
                workDays = from
            } else if (from === 'пн' && to === 'вс') {
                workDays = 'Ежедневно'
            } else {
                workDays = '$from - $to'
            }

            schedules.push(`${workDays} ${times}`)
        },

        async loadList() {
            this.loading = true
            await this.$outlet.loadList({
                cart_items: this.$store.state.cartItems,
            })
            this.loading = false
        },
    },

    created() {
        if (!this.$outlet.list.length) this.loadList()
    }
}
</script>
