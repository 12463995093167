<template>
  <template-item
    v-if="item"
    v-slot="{ object, rules }"
    :model="$lineProductGroup"
    :show-actions="$auth.hasRight('tracker__edit_information')"

    :title="`Группы товаров для линий / ${item.name}`"
  >
    <v-row align="center" no-gutters>
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div class="mt-3 mr-3" v-bind="attrs" v-on="on">
            <v-icon :style="`color: ${item.color}`" size="30"
              >mdi-checkbox-blank-circle</v-icon
            >
          </div>
        </template>
        <v-card v-if="$auth.hasRight('tracker__edit_information')">
          <v-color-picker
            v-model="item.color"
            dot-size="30"
            mode.sync="hex"
          ></v-color-picker>
        </v-card>
      </v-menu>
      <v-text-field
        v-model="item.name"
        class="mt-3"
        dense
        hide-details
        label="Наименование"
        outlined
      ></v-text-field>
    </v-row>
    <v-col class="pa-0">
      <v-row align="center" class="my-5" no-gutters>
        <div class="font-weight-medium">Товары</div>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$auth.hasRight('tracker__edit_information')"
          class="text-none"
          color="primary"
          depressed
          @click="dialog = true"
          >Изменить
        </v-btn>
      </v-row>
      <v-col
        v-for="(product, index) in item.products"
        :key="index"
        class="pa-0"
      >
        <v-row no-gutters>
          <v-img
            :src="product.image || '/static/not-found.png'"
            aspect-ratio="1"
            contain
            height="40"
            max-width="40"
          />
          <div class="text-body-2 ml-4" v-text="product.name" />
        </v-row>
      </v-col>
    </v-col>
    <v-dialog v-model="dialog" max-width="700">
      <v-card class="pa-5">
        <v-row class="mb-3" no-gutters>
          <v-btn color="primary" depressed @click="accept">Сохранить</v-btn>
          <v-spacer></v-spacer>
          <search v-model="search" :rounded="false" always></search>
        </v-row>
        <v-col class="pa-0">
          <v-col
            v-for="(group, groupIndex) in $productGroup.list"
            v-if="
              filterItems(group.products.filter((val) => val.active)).length
            "
            :key="groupIndex"
            class="pa-0"
          >
            <div class="font-weight-bold">{{ group.name }}</div>
            <v-col
              v-for="(product, index) in filterItems(
                group.products.filter((val) => val.active)
              )"
              :key="index"
              class="pa-0"
            >
              <v-row
                align="center"
                class="black--text"
                no-gutters
                @click="setProduct(product)"
              >
                <v-checkbox
                  :input-value="selectedItemIds.indexOf(product.id) > -1"
                  class="pa-0 ma-0"
                  dense
                  hide-details
                ></v-checkbox>
                <v-img
                  :src="product.image || '/static/not-found.png'"
                  aspect-ratio="1"
                  contain
                  height="40"
                  max-width="40"
                />
                <div class="text-body-2 ml-4 link" v-text="product.name" />
              </v-row>
            </v-col>
          </v-col>
        </v-col>
      </v-card>
    </v-dialog>
  </template-item>
</template>

<script>
import search from "air-vue-model/search";
import Search from "@/components/template/Search";

export default {
  name: "LineProductGroup",
  components: {
    Search,
  },
  data: () => ({
    dialog: false,
    search: null,
    selectedItems: [],
  }),
  methods: {
    accept() {
      this.item.products = this.selectedItems;
      this.dialog = false;
    },
    filterItems(items) {
      return search(this.search, items);
    },
    setProduct(value) {
      const index = this.selectedItemIds.indexOf(value.id);
      if (index === -1) this.selectedItems.push(value);
      else this.selectedItems.splice(index, 1);
    },
  },
  computed: {
    item() {
      return this.$lineProductGroup.item;
    },
    selectedItemIds() {
      return this.selectedItems.map((v) => v.id);
    },
  },
  created() {
    console.log(this.$productGroup);
    if (!this.$productGroup.list.length) this.$productGroup.loadList();
  },
  watch: {
    dialog(val) {
      if (val) this.selectedItems = this.$productGroup.copy(this.item.products);
    },
  },
};
</script>

<style scoped></style>
