const m = require("air-vue-model")();
m.url = "users";
m.name = "user";
m.text = {
  itemTitle: "Пользователь",
  listTitle: "Пользователи",
};
m.default = {
  id: null,
  phone: "",
  first_name: "",
  outlets: [],
  user_type: 1,
  password: null,
};

m.userTypeEnum = {
  CUSTOMER: 1,
  OWNER: 2,
  WORKER: 3,
  COURIER: 4,
  MANAGER: 5,
};

m.userTypeName = {
  [m.userTypeEnum.CUSTOMER]: "Покупатель",
  [m.userTypeEnum.OWNER]: "Владелец",
  [m.userTypeEnum.WORKER]: "Работник",
  [m.userTypeEnum.COURIER]: "Курьер",
  [m.userTypeEnum.MANAGER]: "Управляющий",
};

m.userTypes = Object.keys(m.userTypeName).map((v) => {
  return { value: Number(v), title: m.userTypeName[Number(v)] };
});


m.filters = {
  search: null,
  user_type: null,
  detail_data: true,
  ordering: null,
};
//
// m.loadList = (params={}, settings={}) => {
//     const _params = {}
//     if (m.filter.userType)
//         _params.user_type = m.filter.userType
//     if (m.filter.search)
//         _params.search = m.filter.search
//     return m._loadList(Object.assign(_params, params), {})
// }

m.routes = [
  {
    name: "list",
    component: require("@/admin/views/users/Users"),
  },
  {
    name: "item",
    component: require("@/admin/views/users/UserItem"),
    single: true,
    redirect: {
      name: "userBase",
    },
    children: [
      {
        name: "base",
        path: "base",
        component: require("@/admin/views/users/User.vue"),
      },
      {
        name: "balanceOperations",
        path: "balance_operations",
        component: require("@/admin/views/users/UserBalance.vue"),
      },
      {
        name: "notificationsSettings",
        path: "notifications_settings",
        component: require("@/admin/views/users/UserNotificationsSettings.vue"),
      },
    ],
  },
];

module.exports = m;
