<template>
    <v-col v-if="object" class="pa-0">
        <v-row align="center" class="pl-3 flex-nowrap" no-gutters v-if="showHeader">
            <v-btn
                :to="backRoute"
                icon
                @click="!backRoute ? $router.back() : undefined"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <div
                v-if="_title || titlePrepend"
                class="text-h5 font-weight-bold px-3 py-0 py-3"
            >
                {{ titlePrepend }}
                {{ _title }}
            </div>
            <template v-if="$slots.afterTitle">
                <slot name="afterTitle"></slot>
            </template>
        </v-row>
        <template-item-actions
            v-if="showActions"
            :delete-loading="deleteLoading"
            :pk="object[model.pk]"
            :save-loading="saveLoading"
            :show-delete="showDelete"
            @delete="destroy()"
            @save="save()"
        />
        <v-divider v-if="showHeader || showActions" class="my-1"/>
        <v-col class="ma-0" :class="contentClasses || 'pa-3'">
            <v-form ref="form" v-model="$store.state.form">
                <slot :object="object" :rules="rules"></slot>
            </v-form>
        </v-col>
    </v-col>
</template>

<script>
import rules from "../../vendor/rules";

export default {
    name: "TemplateItem",

    mixins: [rules],

    props: {
        model: Object,
        backRoute: Object,
        backOnAction: {
            type: Boolean,
            default: true,
        },
        routeBackOnAction: {
            type: String,
            default: null,
        },
        fluid: {
            type: Boolean,
            default: false,
        },
        titlePrepend: String,
        objectKeyForTitle: String,
        title: String,
        validate: Boolean,
        showActions: {
            type: Boolean,
            default: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        beforeSave: {
            type: Function
        },
        afterSave: {
            type: Function
        },
        contentClasses: {
            type: String,
            required: false
        },
        getSaveData: {
          type: Function,
          required: false
        }
    },
    data: () => ({
        saveLoading: false,
        deleteLoading: false,
    }),

    computed: {
        _title() {
            if (this.title) return this.title;
            if (this.objectKeyForTitle) {
                try {
                    return this.object[this.objectKeyForTitle];
                } catch {
                    void 0
                }
            }
            return this.model.text?.itemTitle;
        },
        object() {
            return this.model.item;
        },
        paramsName() {
            return `${this.model.name}${
                this.model.pk.charAt(0).toUpperCase() + this.model.pk.slice(1)
            }`;
        },
    },
    mounted() {
        if (this.validate) this.$refs.form.validate();
    },
    methods: {
        save() {
            if (this.$props.beforeSave) this.$props.beforeSave(this.object)
            this.saveLoading = true;
            const newObject = !this.object[this.model.pk];
            const saveData = this.getSaveData ? this.getSaveData(this.object) : this.object
            if (newObject) {
                this.model
                    .create(saveData)
                    .then((value) => {
                        this.successSave(newObject);
                    })
                    .catch((err) => {
                        this.failSave(newObject);
                    });
            } else {
                this.model
                    .update(saveData)
                    .then((value) => {
                        this.successSave(newObject);
                    })
                    .catch((err) => {
                        this.failSave(newObject);
                    });
            }
        },
        successSave(newObject) {
            this.saveLoading = false;
            this.$store.commit("setSnackbar", {
                color: "success",
                text: "Сохранено",
            });
            if (newObject)
                this.$router.replace({
                    name: `${this.model.name}Item`,
                    params: {[this.paramsName]: this.object[this.model.pk]},
                });
            if (this.$props.afterSave) this.$props.afterSave(this.object)
            this.saveLoading = false;
        },
        failSave(newObject) {
            this.$store.commit("setSnackbar", {
                color: "red",
                text: `Ошибка ${newObject ? "создания" : "обновления"}`,
            });
            this.saveLoading = false;
        },

        destroy() {
            this.deleteLoading = true;
            this.model
                .destroy()
                .then(() => {
                    this.model.deleteByPk(this.object[this.model.pk]);
                    this.$router.push({name: `${this.model.name}List`});
                    this.$store.commit("setSnackbar", {
                        color: "success",
                        text: "Удалено",
                    });
                    this.deleteLoading = false;
                })
                .catch((err) => {
                    this.$store.commit("setSnackbar", {
                        color: "red",
                        text: "Ошибка удаления",
                    });
                    this.deleteLoading = false;
                });
        },
    },
    created() {
    },
};
</script>
