const m = require('air-vue-model')()
const airLeaflet = require('air-vue-leaflet')()
m.url = 'shifts'
m.name = 'shift'
m.setPagination({
    page_size: 'all'
})

m.text = {
    listTitle: 'Смены', itemTitle: 'Смена',
}

m.routes = [
    {name: 'list', component: require('../admin/views/shift/Shifts')},
    {name: 'item', component: require('../admin/views/shift/Shift'), single: true},
]

m.statusEnum = {
    WAITING: 1,
    WORKING: 2,
    GOING_BACK: 3,
    BREAK: 4,
}

m.statusName = {
    [-1]: 'Не на смене',
    [0]: 'Берет заказ',
    [m.statusEnum.WAITING]: 'Ожидает',
    [m.statusEnum.WORKING]: 'В пути',
    [m.statusEnum.GOING_BACK]: 'Возвращается',
    [m.statusEnum.BREAK]: 'Перерыв',
}

m.generateMapLayer = (shifts, clickFunction = null,) => {
    const points = []
    for (const shift of shifts) {
        const coords = shift.last_position
        points.push({id: shift.id, coords: [coords[0], coords[1]]})
    }
    const pointCollection = airLeaflet.pointCollection(points)
    return L.geoJson(pointCollection, {
        onEachFeature: (feature, layer) => {
            if (clickFunction) {
                layer.on('click', () => clickFunction(feature, layer))
            }
        }, pointToLayer: (feature, latlng) => {

            const draw_icon = new L.icon({
                iconUrl:  '/static/courier-ico.png', iconSize: [30, 30],
            })
            return L.marker(latlng, {
                icon: draw_icon
            })
        },
    })
}

module.exports = m