<template>
    <v-col class="pa-0" v-if="item">
        <v-checkbox v-model="item.active" hide-details class="mb-6" label="Действует"></v-checkbox>
        <v-text-field class="mt-5 mb-8"
                      type="number"
                      outlined dense hide-details v-model.number="item.percent"
                      label="Общий процент"></v-text-field>
        <v-row align="center" no-gutters class="blue-grey lighten-5 py-5 mb-5 px-3 rounded-lg">
            <v-col cols="6" class="font-weight-bold">Позиция</v-col>
            <v-col cols="3" class="text-center font-weight-bold">Процент</v-col>
            <v-col cols="2" class="text-center font-weight-bold">Приоритет</v-col>
            <v-col cols="1" class="text-center font-weight-bold"></v-col>
        </v-row>
        <v-row no-gutters align="center" class="title font-weight-bold my-5">Группы
            <span v-if="$auth.hasRight('discount_systems__edit_information')"
                  class="ml-3 primary--text body-2 font-weight-bold cursor-pointer" @click="selectGroups = true">
                Добавить группу +
            </span>
        </v-row>
        <v-col class="pa-0" v-for="(el, index) in item.category_discounts" :key="index">
            <v-divider v-if="index" class="my-3"></v-divider>
            <DiscountSystemElement :item="el" @click="editItem(el, el.category.name, item.category_discounts, index)">
                <v-row no-gutters align="center">
                    <div class="mr-2 font-weight-medium">{{ index + 1 }}. {{
                            el.category.vendor_name || 'Отсутствует'
                        }}
                    </div>
                    <div class="text-caption mr-2" v-if="el.category.name">({{ el.category.name }})</div>
                </v-row>
            </DiscountSystemElement>
        </v-col>
        <v-row no-gutters align="center" class="title font-weight-bold my-5">Блюда
            <span v-if="$auth.hasRight('discount_systems__edit_information')"
                  class="ml-3 primary--text body-2 font-weight-bold cursor-pointer" @click="selectProducts = true">
                Добавить блюдо +
            </span>
        </v-row>
        <v-col class="pa-0" v-for="(el, index) in item.product_discounts" :key="index">
            <v-divider v-if="index" class="my-3"></v-divider>
            <DiscountSystemElement :item="el" @click="editItem(el, el.product.name, item.product_discounts, index)">
                <v-row no-gutters align="center">
                    <v-img max-width="30" height="30" aspect-ratio="1" contain
                           :src="el.product.image || '/static/not-found.png'"/>
                    <div class="ml-4 font-weight-medium">
                        {{ el.product.vendor_name || 'Отсутствует' }}
                    </div>
                    <div class="text-caption ml-2" v-if="el.product.name">({{ el.product.name }})</div>
                    <v-icon v-if="inOtherSystems(el.product)" class="ml-2 red--text">mdi-information-outline</v-icon>
                </v-row>
                <template v-slot:actions>
                    <v-btn @click.stop="loadPigies(el)" small icon>
                        <v-icon size="20">mdi-sitemap</v-icon>
                    </v-btn>
                </template>
            </DiscountSystemElement>
            <v-col class="pa-0" v-for="(pigi, pigiIndex) in el.ingredient_discounts" :key="pigiIndex">
                <DiscountSystemElement :item="pigi"
                                       @click="editItem(pigi, pigi.ingredient.ingredient.name, el.ingredient_discounts, pigiIndex)">
                    <v-row no-gutters align="center" class="body-2 pl-12">
                        <v-img max-width="30" height="30" aspect-ratio="1" contain
                               :src="pigi.ingredient.ingredient.image || '/static/not-found.png'"/>
                        <div class="ml-4">
                            {{ pigi.ingredient.ingredient.vendor_name || 'Отсутствует' }}
                        </div>
                        <div class="text-caption ml-2" v-if="pigi.ingredient.ingredient.name">
                            ({{ pigi.ingredient.ingredient.name }})
                        </div>
                    </v-row>
                </DiscountSystemElement>
            </v-col>
        </v-col>
        <v-dialog v-model="selectGroups" max-width="700">
            <v-card class="pa-5">
                <div class="mb-5 font-weight-bold">Выбор группы</div>
                <v-col class="pa-0" v-for="(el, index) in $productGroup.list" :key="index">
                    <v-row class="link py-1" no-gutters align="center" @click="selectGroup(el)">
                        <div class="mr-2">{{ el.vendor_name || 'Отсутствует' }}</div>
                        <div class="text-caption mr-2" v-if="el.name">({{ el.name }})</div>
                    </v-row>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="selectProducts" max-width="700">
            <v-card class="pa-5">
                <div class="mb-5 font-weight-bold">Выбор блюда</div>
                <v-row no-gutters align="center" class="my-4">
                    <!--                    <v-input dense outline v-model="search" label="Поиск"></v-input>-->
                    <v-checkbox class="ma-0 pa-0" v-model="onlyActive" hide-details
                                label="Только отображаемые в приложении"></v-checkbox>
                    <v-spacer></v-spacer>
                    <search class="ml-3" :rounded="false" always v-model="search"></search>
                </v-row>
                <v-col class="px-0 py-1" v-for="(el, index) in products" :key="index">
                    <v-hover v-slot="{hover}">
                        <v-row no-gutters align="center" :class="el.show_in_app || hover ? 'link' : 'secondary--text'"
                               @click="selectProduct(el)">
                            <v-img max-width="30" height="30" aspect-ratio="1" contain
                                   :src="el.image || '/static/not-found.png'"/>
                            <div class="mr-2 ml-2">{{ el.vendor_name || 'Отсутствует' }}</div>
                            <div class="text-caption mr-2" v-if="el.name">({{ el.name }})</div>
                        </v-row>
                    </v-hover>
                </v-col>
            </v-card>
        </v-dialog>
        <v-dialog v-model="pigiesData.dialog" max-width="700">
            <v-card class="pa-5">
                <div class="mb-5 font-weight-bold">Выбор модификатора</div>
                <div v-for="(el, index) in pigiesData.pigiesGroups" :key="index">
                    <div class="font-weight-bold my-2">{{ el.ingredient_group.name }}</div>
                    <div @click="selectIngredient(pigi)" v-for="(pigi, pigiIndex) in el.ingredients"
                         :key="pigiIndex">
                        <v-row no-gutters align="center" class="body-2 py-1 link">
                            <v-img max-width="30" height="30" aspect-ratio="1" contain
                                   :src="pigi.ingredient.image || '/static/not-found.png'"/>
                            <div class="ml-4">
                                {{ pigi.ingredient.vendor_name || 'Отсутствует' }}
                            </div>
                            <div class="text-caption ml-2" v-if="pigi.ingredient.name">
                                ({{ pigi.ingredient.name }})
                            </div>
                        </v-row>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editData.dialog" max-width="700">
            <v-card class="pa-5">
                <div class="mb-5 font-weight-bold">{{ editData.name }}
                </div>
                <v-text-field outlined dense hide-details
                              type="number"
                              v-model.number="editData.priority" label="Приоритет"></v-text-field>
                <v-text-field class="mt-5"
                              type="number"
                              outlined dense hide-details v-model.number="editData.percent"
                              label="Процент начисления"></v-text-field>
                <v-row no-gutters align="center" justify="space-between" class="mt-5">
                    <v-btn @click="approveItem" class="text-none" outlined color="primary">Подтвердить</v-btn>
                    <v-btn @click="deleteItem" class="text-none" outlined color="error">Удалить</v-btn>
                </v-row>
            </v-card>
        </v-dialog>
    </v-col>
</template>

<script>
import Search from "@/components/template/Search.vue";
import search from "air-vue-model/search";
import DiscountSystemElement from "@/admin/views/discountSystems/DiscountSystemElement.vue";

export default {
    name: 'DiscountSystem',
    props: {
        item: Object
    },
    components: {
        DiscountSystemElement,
        Search
    },
    data: () => ({
        selectGroups: false,
        selectProducts: false,
        saveLoading: false,
        onlyActive: true,
        search: '',
        editData: {
            dialog: false,
            name: null,
            percent: 0,
            priority: 0,
            items: [],
            item: null,
            index: -1
        },
        pigiesData: {
            pigiesGroups: [],
            dialog: false,
            productDiscount: null
        }
    }),
    computed: {
        products() {
            let result = [].concat(...this.$productGroup.list.map(v => v.products))
            result = search(this.search, result, val => val.vendor_name)
            if (!this.onlyActive) return result
            return result.filter(v => v.show_in_app)
        },
    },
    methods: {
        editItem(item, name, items, index) {
            this.editData.item = item
            this.editData.priority = item.priority
            this.editData.percent = item.percent
            this.editData.dialog = true
            this.editData.items = items
            this.editData.name = name
            this.editData.index = index
        },
        approveItem() {
            this.editData.item.percent = this.editData.percent
            this.editData.item.priority = this.editData.priority
            this.editData.dialog = false
        },
        deleteItem() {
            if (this.editData.index > -1)
                this.editData.items.splice(this.editData.index, 1)
            this.editData.dialog = false
        },
        loadPigies(productDiscount) {
            this.pigiesData.productDiscount = productDiscount
            this.$product.loadItem(this.pigiesData.productDiscount.product.id).then(res => {
                this.pigiesData.pigiesGroups = res.product_ingredient_groups
                this.pigiesData.dialog = true
            })
        },
        selectIngredient(el) {
            this.pigiesData.productDiscount.ingredient_discounts.push({
                ingredient: el,
                percent: 0,
                priority: 2
            })
            this.pigiesData.dialog = false
        },
        inOtherSystems(product) {
            const id = product.id
            const products = this.item.product_discounts.filter(v => v.product.id === id)
            if (products.length > 1) return true
            // const groups = this.currentGroups.filter(v => v.group.id === product.group)
            // return groups.length > 0;

        },
        selectProduct(product) {
            this.selectProducts = false
            this.item.product_discounts.push({
                product: product,
                percent: 0,
                priority: 1,
                ingredient_discounts: []
                // price: 0
            })
        },
        selectGroup(group) {
            this.selectGroups = false
            this.item.category_discounts.push({
                category: group,
                percent: 0,
                priority: 0
            })
        }
    }
}
</script>

<style scoped>

</style>