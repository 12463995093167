export function handle(error, storeInstance) {
    const status = error.response.status
    if (status === 400) {
        const data = error.response.data
        let errorMessage = ''

        if (typeof data === 'object') {
            for (const [key, value] of Object.entries(data)) {
                if (Array.isArray(value)) {
                    for (const singleValue of value) {
                        errorMessage += `${key}: ${singleValue}\n`
                    }
                } else {
                    errorMessage += `${key}: ${value}\n`
                }
            }
        } else if (Array.isArray(data)) {
            for (const value of data) {
                errorMessage += `${value}\n`
            }
        } else if (typeof data === 'string') {
            errorMessage = data
        } else errorMessage = 'Неизвестная ошибка.'

        if (errorMessage)
            storeInstance.commit('setSnackbar', {
                text: errorMessage,
                color: 'error'
            })
    } else if (status === 500) {
        storeInstance.commit('setSnackbar', {
            text: 'Произошла непредвиденная ошибка сервера.',
            color: 'error'
        })
    }
}
