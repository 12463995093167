<template>
  <div>
    <v-row align="center" class="px-4 mb-6 my-0">
      <div class="text-h6 mr-3">Основная информация</div>
      <v-divider />
      <v-btn
        v-if="$auth.hasRight('products__edit_information')"
        :loading="saveLoading"
        height="40"
        depressed
        class="rounded-lg ml-3"
        color="primary"
        @click="save()"
      >
        <div class="text-none body-2 px-2">Сохранить</div>
      </v-btn>
    </v-row>
    <v-checkbox
      class="pa-0 ma-0 mt-2"
      hide-details
      label="Отображать в приложении"
      v-model="$productIngredientGroup.item.show_in_app"
    ></v-checkbox>
    <v-divider class="my-5"></v-divider>
    <v-checkbox
      v-model="$productIngredientGroup.item.hidden"
      hide-details
      class="pa-0 ma-0"
      label="Скрытая"
    ></v-checkbox>
    <v-checkbox
      v-model="$productIngredientGroup.item.use_in_total_weight"
      hide-details
      class="pa-0 ma-0 mt-4"
      label="Использовать для расчета веса"
    ></v-checkbox>
    <v-checkbox
      v-model="$productIngredientGroup.item.use_in_nutritional_value"
      hide-details
      class="pa-0 ma-0 mt-4"
      label="Использовать для расчета пищевой ценности"
    ></v-checkbox>
    <v-text-field
      v-model="$productIngredientGroup.item.sorting"
      type="number"
      outlined
      hide-details
      dense
      label="Сортировка"
      class="my-4"
    />
  </div>
</template>

<script>
import ProductIngredientGroupTabs from "@/admin/views/productIngredientGroupIngredient/ProductIngredientGroupTabs.vue";
import {clone, cloneDeep, isEqual} from "lodash";
import {useEventListener} from "@/plugins/useEventListener";

export default {
  name: "ProductIngredientGroupItem",
  components: { ProductIngredientGroupTabs },
  data: () => ({
    saveLoading: false,
    initialItem: null,
    eventsToClear: [],
  }),
  mounted() {
    this.$eventBus.$on("productIngredientGroupItemSaveFinal", () => {
      this.saveLoading = false;
    });
    this.$eventBus.$on("productIngredientGroupItemSaved", () => {
      this.initialItem = cloneDeep(this.$productIngredientGroup.item)
    })
    this.initialItem = clone(this.$productIngredientGroup.item);
    this.eventsToClear.push(this.$router.beforeEach((to, from, next) => {
      if (!isEqual(this.initialItem, this.$productIngredientGroup.item)) {
        const answer = window.confirm(
          "Если вы уйдете со страницы, то потеряете все несохраненные данные"
        );
        if (answer) {
          if (this.eventsToClear.length) this.eventsToClear.map(v => v());
          next();
        } else {
          this.$store.commit('setProductDrawer', true)
        }
      } else next()
    }))
    this.eventsToClear.push(useEventListener(window, 'beforeunload', () => {
      if (!isEqual(this.initialItem, this.$productIngredientGroup.item)) {
        return confirm()
      }
    }))
  },
  beforeDestroy() {
    if (this.eventsToClear.length) this.eventsToClear.map(v => v())
  },

  methods: {
    isEqual,
    save() {
      this.saveLoading = true;
      this.$eventBus.$emit("saveProductIngredientGroupItem");

    },
  },
};
</script>
