<template>
  <v-row class="ma-0">
    <v-col class="px-0" cols="4">
      <OrderReviewsInChat
        :active-item="activeItem"
        :items="$orderReview.list"
        @clicked="activeItem = $event"
        @loadNextPage="loadReviews(true)"
      />
    </v-col>
    <v-divider class="ma-0" vertical />
    <v-col>
      <Chat
        v-if="activeItem"
        :item="activeItem"
        @updateItem="updateActiveItem"
      />
    </v-col>
  </v-row>
</template>

<script>
import OrderReviewsInChat from "./chat/OrderReviewsInChat";
import Chat from "@/admin/views/orderReviews/chat/Chat.vue";
import { cloneDeep } from "lodash";

export default {
  name: "OrderReviews",
  components: {
    Chat,
    OrderReviewsInChat,
  },
  data: () => {
    return {
      activeItem: null,
      websocket: null,
      closeWsAllowed: false,
      wsReconnectInterval: null,
      loading: false,
    };
  },
  mounted() {
    if (this.$route.params.review_id) {
      this.$orderReview.loadItem(this.$route.params.review_id).then(v => {
        this.activeItem = v
      })
    }
    this.loadReviews(false, 1);
    this.setWebsocket();
    this.$eventBus.$on("orderReviewNewMessage", (data) => {
      if (data.ticket.subject_type !== "order_review") return;
      const item = this.$orderReview.list.find(
        (v) => v.external_ticket_id === data.ticket?.id
      );
      if (!item) return;
      this.updateLastMessage(item, data);
      this.updateUnreadMessagesCount(item, (count) => count + 1);
    });
    this.$eventBus.$on("orderReviewMessageUpdated", (data) => {
      if (data.ticket.subject_type !== "order_review") return;
      const item = this.$orderReview.list.find(
        (v) => v.external_ticket_id === data.ticket?.id
      );
      if (!item) return;
      this.updateLastMessage(item, data);
    });
    this.$eventBus.$on("orderReviewMessageDeleted", (data) => {
      if (data.current_last_message) {
        const item = this.$orderReview.list.find(
          (v) => v.external_ticket_id === data.current_last_message?.ticket?.id
        );
        if (!item) return;
        if (
          data.id === item.last_message_data?.id &&
          (item.unread_messages_count || 0) > 0
        ) {
          this.updateUnreadMessagesCount(item, (count) =>
            count ? count - 1 : 0
          );
        }
        this.updateLastMessage(item, data.current_last_message);
      }
    });
    this.$eventBus.$on("loadOrderReviews", () => {
      const scroller = document.querySelector("#reviews-scroller");
      if (scroller) scroller.scrollTo(0, 0);
      this.loadReviews(false, 1);
    });
  },
  methods: {
    updateActiveItem(item) {
      this.activeItem = cloneDeep(item);
      const index = this.$orderReview.list.findIndex((v) => v.id === item.id);
      if (index > -1) this.$orderReview.list[index] = cloneDeep(item);
    },
    setWebsocket() {
      if (this.wsReconnectInterval) clearInterval(this.wsReconnectInterval);
      if (this.closeWsAllowed) return;
      const wsUrl = `wss://app.rosterhit.ru/ws/users/service_app_${this.$auth.user.id}/`
      // const wsUrl = `ws://127.0.0.1:8100/ws/users/service_app_${this.$auth.user.id}/`
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.wsMessageHandler;
      this.websocket.onclose = () => {
        this.wsReconnectInterval = setInterval(() => {
          this.setWebsocket();
        }, 5000);
      };
    },
    updateUnreadMessagesCount(item, getNewCount) {
      item.unread_messages_count = getNewCount(
        item.unread_messages_count === undefined
          ? 0
          : item.unread_messages_count
      );
    },
    updateLastMessage(item, lastMessageData) {
      const lastMessage = item.last_message_data;
      lastMessage.user_data = lastMessageData.user_data;
      lastMessage.text = lastMessageData.text;
    },
    wsMessageHandler(res) {
      const data = JSON.parse(res.data);
      if (data.type === "new_message") {
        this.$eventBus.$emit("orderReviewNewMessage", data.data);
      } else if (data.type === "message_updated") {
        this.$eventBus.$emit("orderReviewMessageUpdated", data.data);
      } else if (data.type === "message_deleted") {
        this.$eventBus.$emit("orderReviewMessageDeleted", data.data);
      }
    },
    loadReviews(nextPage = false, setPage) {
      if (this.loading) return;
      this.loading = true;
      const lastPage = this.$orderReview.pagination.last_page;
      if (nextPage) {
        if (this.$orderReview.pagination.page < lastPage)
          this.$orderReview.pagination._page++;
        else return;
      }
      this.$orderReview
        .loadList(
          {
            page: setPage || this.$orderReview.pagination.page,
            include_unread_messages: true,
          },
          { setToModel: !nextPage, setFirstPage: !nextPage }
        )
        .then((v) => {
          if (nextPage) this.$orderReview.list.push(...v.results);
        })
        .finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    this.closeWsAllowed = true;
    this.websocket.close();
  },
};
</script>
<style scoped></style>
