<template>
    <v-col class="pa-0 py-1 px-3 body-2">
        <v-row no-gutters align="center" style="min-height: 40px">
            <slot :object="object" :to="to" :index="index"></slot>
        </v-row>
    </v-col>
</template>

<script>
    export default {
        name: "ListElement",
        components: {},
        props: {
            model: Object,
            object: Object,
            index: Number,
            first: Boolean,
            last: Boolean,
        },
        computed: {
            to() {
                return { name: `${this.model.name}Item`, params: { [`${this.model.name}Id`]: this.object.id } }
            }
        }
    }
</script>
