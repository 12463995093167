import { render, staticRenderFns } from "./OutletRatings.vue?vue&type=template&id=945fa2d6&scoped=true&"
import script from "./OutletRatings.vue?vue&type=script&lang=js&"
export * from "./OutletRatings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "945fa2d6",
  null
  
)

export default component.exports