<template>
  <v-dialog
    v-model="modelValue"
    @close="$emit('update:modelValue', false)"
    @click:outside="$emit('update:modelValue', false)"
    :width="500"
  >
    <v-card class="pa-3" height="auto" width="500" v-if="shift && data">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            size="25px"
            style="position: absolute; top: 10px; right: 10px"
            >mdi-dots-vertical
          </v-icon>
        </template>
        <v-card class="pa-0 rounded-lg">
          <v-list>
            <v-list-item
              v-if="shift.status !== $shift.statusEnum.BREAK"
              @click="startBreakWithConfirm"
              >Начать перерыв
            </v-list-item>
            <v-list-item v-else @click="endBreakWithConfirm"
              >Закончить перерыв
            </v-list-item>
            <v-divider />
            <v-list-item @click="endShiftWithConfirm">
              <div class="red--text">Закончить смену</div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-row class="ma-0">
        <div
          style="
            width: 130px;
            height: 130px;
            border-radius: 50%;
            border: 1px solid #dedede;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <v-icon size="155px">mdi-account-circle</v-icon>
        </div>
        <v-col class="pa-0 ml-5">
          <v-row class="ma-0 pr-8" align="center">
            <div class="title font-weight-bold">
              {{ shift.user.first_name }}
            </div>
            <div class="font-weight-regular body-2 ml-2">
              ({{ $user.userTypeName[shift.user.user_type] }})
            </div>
          </v-row>
          <div class="body-1">
            <span class="font-weight-bold grey--text text--darken-3"
              >Статус:
            </span>
            {{
              shift.end_date ? "Не на смене" : $shift.statusName[shift.status]
            }}
          </div>
          <div class="body-1">
            <span class="font-weight-bold grey--text text--darken-3"
              >Оплата за час:
            </span>
            {{ shift.reward_per_hour }} ₽
          </div>
          <template v-if="!shift.end_date">
            <!--            <div class="body-1">Начало смены: {{ shiftStartDate }}</div>-->
            <div class="body-1">
              <span class="font-weight-bold grey--text text--darken-3"
                >Время статуса:
              </span>
              {{ getMinutes(shift) }}
            </div>
            <div
              class="body-1"
              v-if="shift.status === $shift.statusEnum.GOING_BACK"
            >
              <span class="font-weight-bold grey--text text--darken-3"
                >Вернется через:
              </span>
              {{ getMinutes(shift, true) }}
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-2" align="center">
        <div class="title">
          {{ tab === "analytics" ? "Аналитика" : "Заказы" }}
        </div>
        <v-divider class="ml-3" />
        <v-btn
          depressed
          class="ml-3"
          @click="tab = tab === 'analytics' ? 'orders' : 'analytics'"
        >
          <div>{{ tab === "analytics" ? "Заказы" : "Аналитика" }}</div>
        </v-btn>
      </v-row>
      <v-col class="pa-0 mt-2" v-if="tab === 'analytics'">
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Время на смене:
          </span>
          {{ secondsToTime(data.total_shifts_time) }}
        </div>
        <div class="body-2">
          <span class="font-weight-bold grey--text text--darken-3"
            >Доставлено заказов:
          </span>
          {{ data.delivered_orders }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Средний рейтинг заказов:
          </span>
          <div style="display: inline-flex; align-items: center">
            {{ data.avg_orders_rating }}
            <v-icon style="margin-left: 2px" size="18px">mdi-star</v-icon>
          </div>
        </div>

        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Общее время в пути:
          </span>
          {{ secondsToTime(data.total_going_time) }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Время в пути к клиенту:
          </span>
          {{ secondsToTime(data.delivering_time) }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Время возвращения:
          </span>
          {{ secondsToTime(data.go_back_time) }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Время перерывов:
          </span>
          {{ secondsToTime(data.breaking_time) }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Оплачиваемое время на смене:
          </span>
          {{ secondsToTime(data.paying_time) }}
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Доход за время на смене:
          </span>
          {{ data.reward_by_hours }} ₽
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Доход за доставленные заказы:
          </span>
          {{ data.reward_by_orders }} ₽
        </div>
        <div class="body-2 mt-1">
          <span class="font-weight-bold grey--text text--darken-3"
            >Общий доход:
          </span>
          {{ data.total_reward }} ₽
        </div>
      </v-col>
      <v-row v-else class="ma-0" style="max-height: 400px; overflow-y: auto">
        <v-col
          class="pa-0"
          cols="12"
          v-for="(order, index) in orders"
          :key="order.id"
          @click="$emit('openOrder', order)"
          style="cursor: pointer"
        >
          <v-divider v-if="index" class="my-2" />
          <div class="body-2 font-weight-bold">{{ order.daily_number }}</div>

          <div class="body-2">
            <span class="font-weight-bold grey--text text--darken-3"
              >Адрес:
            </span>
            {{ order.delivery_address?.address }}
          </div>
          <div class="body-2">
            <span class="font-weight-bold grey--text text--darken-3"
            >Сумма:
            </span>
            {{ order.sum }} ₽
          </div>
          <div class="body-2">
            <span class="font-weight-bold grey--text text--darken-3"
            >Оплата курьеру:
            </span>
            {{ order.courier_reward }} ₽
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="confirmDialogModelValue" width="500px">
      <ConfirmDialogData
        text="Вы подтверждаете действие?"
        @confirm="confirm()"
        @closeDialog="confirmDialogModelValue = false"
      >
      </ConfirmDialogData>
    </v-dialog>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ConfirmDialogData from "@/admin/components/ConfirmDialogData.vue";

export default {
  name: "ShiftAnalyticsModal",
  components: { ConfirmDialogData },
  data: () => {
    return {
      confirmDialogModelValue: false,
      onConfirmCallback: null,
      orders: [],
      tab: "analytics",
    };
  },
  props: {
    modelValue: Boolean,
    data: Object,
    shift: Object,
  },
  emits: ["update:modelValue", 'openOrder'],
  computed: {
    shiftStartDate() {
      if (this.shift.start_date) {
        return moment
          .utc(this.shift.start_date)
          .local()
          .format("DD.MM.YYYY HH:mm:ss");
      } else return "kek";
    },
  },
  methods: {
    confirm() {
      if (!this.onConfirmCallback) return;
      this.confirmDialogModelValue = false;
      this.onConfirmCallback();
    },
    endBreakWithConfirm() {
      this.confirmDialogModelValue = true;
      this.onConfirmCallback = () => this.endBreak();
    },
    endBreak() {
      this.$shift
        .sendPostSingle("end_break", this.shift.id)
        .then((v) => {
          this.shift.status = v.result.status;
          this.shift.status_updated_at = v.result.status_updated_at;
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "fail",
            text: "Ошибка",
          });
        });
    },
    startBreakWithConfirm() {
      this.confirmDialogModelValue = true;
      this.onConfirmCallback = () => this.startBreak();
    },
    startBreak() {
      this.$shift
        .sendPostSingle("start_break", this.shift.id)
        .then((v) => {
          this.shift.status = v.result.status;
          this.shift.status_updated_at = v.result.status_updated_at;
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "fail",
            text: "Ошибка",
          });
        });
    },
    endShiftWithConfirm() {
      this.confirmDialogModelValue = true;
      this.onConfirmCallback = () => this.endShift();
    },
    endShift() {
      this.$shift
        .sendPostSingle("end", this.shift.id)
        .then((v) => {
          this.shift.status = -1;
          this.$emit("update:modelValue", false);
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "fail",
            text: "Ошибка",
          });
        });
    },
    secondsToTime(v) {
      const secs = Number(Number(v).toFixed(0));
      let hours = Math.floor(secs / 3600);
      hours = hours < 10 ? `0${hours}` : hours;
      let minutes = Math.floor((secs % 3600) / 60);
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      let seconds = secs - hours * 3600 - minutes * 60;
      seconds = seconds < 10 ? `0${seconds}` : seconds;
      return `${hours}:${minutes}:${seconds}`;
    },
    getMinutes(item, estimated = false) {
      const now = moment();
      if (item.status === this.$shift.statusEnum.GOING_BACK && estimated) {
        if (!item.estimated_going_back_time) return "Н/Д";
        const val = moment.utc(item.estimated_going_back_time).local();

        return val.diff(now, "minutes") + " мин";
      } else {
        const val = moment.utc(item.status_updated_at).local();
        return now.diff(val, "minutes") + " мин";
      }
    },
  },
  watch: {
    tab(v) {
      if (v === "orders") {
        this.$order
          .loadList({
            courier: this.shift.user.id,
            start_created_at: this.shift.start_date,
            user_status: this.$order.userStatusEnum.RELEASED,
            page_size: "all",
          })
          .then((v) => {
            this.orders = v;
          });
      }
    },
  },
};
</script>

<style scoped></style>
