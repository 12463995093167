<template>
  <template-item
    :title="title"
    :model="$outlet"
    :show-actions="false"
    :show-delete="false"
    v-slot="{ object }"
  >
    <v-row no-gutters align="center">
      <v-btn
        @click="tab = 'base'"
        :color="tab === 'base' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Общая информация
      </v-btn>
      <v-btn
        @click="tab = 'delivery'"
        :color="tab === 'delivery' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Зоны доставки
      </v-btn>
      <v-btn
        @click="tab = 'deliverySettings'"
        :color="tab === 'deliverySettings' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Настройки доставки
      </v-btn>
      <v-btn
        @click="tab = 'equipments'"
        :color="tab === 'equipments' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Оборудование
      </v-btn>
      <v-btn
        @click="tab = 'nomenclature'"
        :color="tab === 'nomenclature' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Остатки
      </v-btn>
      <v-btn
        @click="tab = 'database'"
        :color="tab === 'database' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >База данных
      </v-btn>
      <v-btn
        @click="tab = 'lines'"
        :color="tab === 'lines' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Линии
      </v-btn>
      <v-btn
        v-if="$outlet.item.worker_interface === 'tracker'"
        @click="tab = 'tracker_queue'"
        :color="tab === 'tracker_queue' ? 'primary' : ''"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Очередь на трекере
      </v-btn>
      <v-btn
        @click="goToDisplays"
        depressed
        class="mr-3 rounded-lg text-none body-2"
        >Экраны
      </v-btn>
      <v-btn
          @click="tab = 'afterOrderReview'"
          :color="tab === 'afterOrderReview' ? 'primary' : ''"
          depressed
          class="mr-3 rounded-lg text-none body-2"
      >Информация после оценки заказа
      </v-btn>
    </v-row>

    <outlet-item-base :item="object" v-if="tab === 'base'" @update="saveItem"></outlet-item-base>
    <outlet-item-database
      :item="object"
      v-if="tab === 'database'"
    ></outlet-item-database>
    <outlet-item-delivery-areas
      :item="object"
      v-if="tab === 'delivery'"
    ></outlet-item-delivery-areas>
    <outlet-item-equipments
      :item="object"
      v-if="tab === 'equipments'"
    ></outlet-item-equipments>
    <outlet-item-nomenclature
      :item="object"
      v-if="tab === 'nomenclature'"
    ></outlet-item-nomenclature>
    <outlet-item-lines
      :item="object"
      v-if="tab === 'lines'"
    ></outlet-item-lines>
    <outlet-tracker-queue
      :item="object"
      v-if="tab === 'tracker_queue'"
    ></outlet-tracker-queue>
    <outlet-delivery-settings
        @update="saveItem"
      :item="object"
      v-if="tab === 'deliverySettings'"
    ></outlet-delivery-settings>
    <after-order-review
        :item="object"
        v-if="tab === 'afterOrderReview'"
    ></after-order-review>
  </template-item>
</template>

<script>
import OutletItemBase from "@/admin/views/outlets/OutletItemBase";
import OutletItemDatabase from "@/admin/views/outlets/OutletItemDatabase";
import OutletItemDeliveryAreas from "@/admin/views/outlets/OutletItemDeliveryAreas";
import OutletItemEquipments from "@/admin/views/outlets/OutletItemEquipments";
import OutletItemNomenclature from "@/admin/views/outlets/OutletItemNomenclature";
import OutletItemLines from "@/admin/views/outlets/OutletItemLines";
import OutletTrackerQueue from "@/admin/views/outlets/OutletTrackerQueue";
import OutletDeliverySettings from '@/admin/views/outlets/OutletDeliverySettings.vue'
import AfterOrderReview from "@/admin/views/outlets/AfterOrderReview.vue";

export default {
  name: "Outlet",
  components: {
    AfterOrderReview,
    OutletDeliverySettings,
    OutletTrackerQueue,
    OutletItemBase,
    OutletItemDatabase,
    OutletItemDeliveryAreas,
    OutletItemEquipments,
    OutletItemNomenclature,
    OutletItemLines,
  },

  data: () => ({
    tab: "base",
  }),
  created() {
    this.$equipmentConfig.loadList({
      outlet: this.$outlet.item.id,
    });
    this.$ingredientOutletDetail.loadList({
      outlet: this.$outlet.item.id,
    });
  },
  computed: {
    title() {
      const values = [];
      values.push(this.$outlet.text.itemTitle);
      if (this.$outlet.item) {
        values.push(
          this.$outlet.item.number + ". " + this.$outlet.item.address
        );
      }
      return values.join(" / ");
    },
  },

  methods: {
    goToDisplays() {
      this.$displayDevice.filters.outlet = this.$outlet.item.id;
      this.$router.push({ name: "displayDeviceList" });
    },
    saveItem() {
      this.$outlet.update().then(value => {
        this.$store.commit('setSnackbar', {color: 'success', text: 'Торговая точка сохранена'})
      }).catch(err => {
        this.$store.commit('setSnackbar', {color: 'fail', text: 'Не удалось сохранить'})
      })
    },
  },
};
</script>
