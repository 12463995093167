const m = require('air-vue-model')()
m.url = 'ingredient_groups'
m.name = 'ingredientGroup'

m.text = {
    itemTitle: 'Группа модификаторов',
    listTitle: 'Группы модификаторов'
}


m.routes = [{
    name: 'item', component: require('@/admin/views/ingredientGroups/IngredientGroupItem'), single: true,
}]

module.exports = m