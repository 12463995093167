<template>
  <v-row class="ma-0" style="height: calc(100vh - 64px); flex-wrap: nowrap">
    <v-col
      class="pa-0"
      cols="3"
      style="height: inherit; overflow-y: auto; position: relative"
      ref="ordersScroller"
    >
      <v-btn
        v-if="!selectedOrders.length"
        style="position: absolute; right: 20px; top: 14px"
        small
        color="primary"
        @click="initShiftModal = true"
      >
        <div class="text-none body-2">Создать смену</div>
      </v-btn>
      <DeliveryShifts
        :key="`${
          orders.map((v) => v.id) +
          shifts.map((v) => `${v.id}${v.takesAnOrder}`)
        }${selectedShift?.id}${selectedOrders.map((v) => v.id)}`"
        :shifts="shifts"
        :orders="filteredOrders"
        :selected-shift="selectedShift"
        :selected-orders="selectedOrders"
        :total-orders="orders"
        :filter-orders-by-shift="filterOrdersByShift"
        @selectCourier="selectCourier($event)"
        @setOrdersToShift="setOrdersToShift()"
        @filterOrdersByShift="setFiltersOrdersByShift($event)"
        @selectOrder="selectOrder($event)"
        @clear="onClear()"
        class="py-3"
      />
    </v-col>
    <v-divider vertical />
    <v-col class="pa-0" cols="9">
      <v-row class="ma-0 pa-3" justify="space-between">
        <v-row class="ma-0" align="center">
          <v-select
            hide-details
            outlined
            dense
            :items="Object.values(outletById)"
            item-value="id"
            label="ТТ"
            :item-text="(val) => val.number + '. ' + val.address"
            v-model="filters.outlet"
            style="max-width: 200px"
            clearable
            class="mr-4"
          ></v-select>
          <v-checkbox
            label="Только без курьера"
            hide-details
            class="ma-0 pa-0"
            v-model="onlyWithoutCourier"
          />
        </v-row>
        <v-btn color="primary" @click="reviewsModal = true" class="mr-4">
          <div class="text-none">Отзывы</div>
        </v-btn>
        <v-btn color="primary" @click="mapMode = !mapMode" class="mr-4">
          <div class="text-none">
            {{ !mapMode ? "Посмотреть на карте" : "Открыть список заказов" }}
          </div>
        </v-btn>
        <v-btn color="primary" @click="loadAll()">
          <div class="text-none">Обновить</div>
        </v-btn>
      </v-row>
      <v-divider />
      <div style="overflow-y: auto; height: calc(100vh - 130px)">
        <DeliveryOrders
          v-if="!mapMode"
          :orders="filteredOrders"
          @selectOrder="selectOrder($event)"
          :selected-orders="selectedOrders"
        />
        <DeliveryMap
          v-else
          :orders="filteredOrders"
          :shifts="shifts"
          :shifts-key="shiftsRenderKey"
          ref="mapRef"
        />
      </div>
    </v-col>
    <InitShiftModal
      :model-value="initShiftModal"
      @update:modelValue="initShiftModal = $event"
      @shiftInited="updateShift($event, 'shift.start')"
    />
    <v-dialog v-model="reviewsModal" width="400px">
      <v-card v-if="reviewsModal">
        <div class="headline py-2 mx-2">Отзывы</div>
        <div style="max-height: 470px; overflow-y: auto">
          <v-col
            v-for="(orderReview, index) in orderReviews"
            :key="orderReview.id"
            class="px-3 py-0"
          >
            <v-divider v-if="index" class="m-1" />
            <v-col class="px-0 py-2">
              <v-row class="ma-0">
                <div class="body-2 font-weight-bold">
                  {{ orderReview.order.daily_number }}
                </div>
                <v-spacer />
                <div class="caption">
                  {{ parseDate(orderReview.created_at) }}
                </div>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0" cols="9">
                  <v-row class="pa-0 ma-0 body-2">
                    ТТ: {{ orderReview.order.outlet.number }}.
                    {{ orderReview.order.outlet.address }}
                  </v-row>
                  <v-row class="pa-0 ma-0 body-2" align="center">
                    Оценка: {{ orderReview.rating }}
                    <v-icon size="16px" style="margin-bottom: 1px" class="ml-1"
                      >mdi-star
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <div v-if="!orderReviews.length" class="mx-2 mb-2">
            Отзывов пока нет
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DeliveryOrders from "@/admin/views/deliveryAdmin/DeliveryOrders.vue";
import moment from "moment/moment";
import DeliveryShifts from "@/admin/views/deliveryAdmin/DeliveryShifts.vue";
import DeliveryMap from "@/admin/views/deliveryAdmin/DeliveryMap.vue";
import InitShiftModal from "@/admin/views/deliveryAdmin/InitShiftModal.vue";
import { clone } from "lodash";
import orderReviews from "@/admin/views/orderReviews/OrderReviews.vue";

export default {
  components: { InitShiftModal, DeliveryMap, DeliveryShifts, DeliveryOrders },
  data: () => ({
    loading: false,
    filters: {},
    orders: [],
    shifts: [],
    reloadOrdersListInterval: null,
    outletById: {},
    selectedOrders: [],
    selectedShift: null,
    filterOrdersByShift: null,
    outletCourierWs: null,
    tryReconnectOutletCourierWs: false,
    outletCourierWsReconnectInterval: null,
    onlyWithoutCourier: true,
    lastValueOfOnlyWithoutCourier: true,
    initShiftModal: false,
    takesAnOrderManual: false,
    mapMode: false,
    shiftsRenderKey: 0,
    reviewsModal: false,
    orderReviews: [],
  }),
  methods: {
    sortOrders(a, b) {
      const firstPackaged =
        a.user_status === this.$order.userStatusEnum.PACKAGED;
      const secondPackaged =
        b.user_status === this.$order.userStatusEnum.PACKAGED;
      if (firstPackaged && secondPackaged)
        return (a.packaged_at || 0) > (b.packaged_at || 0) ? 1 : -1;
      else if (firstPackaged) return -1;
      else if (secondPackaged) return 1;
      return a.preferred_time > b.preferred_time ? 1 : -1;
    },
    onClear() {
      this.takesAnOrderManual = false;
      this.selectedShift = null;
      this.selectedOrders = [];
      this.updateShiftsLocalStatus();
    },
    updateShiftsLocalStatus() {
      if (this.takesAnOrderManual) return;
      this.shifts.forEach((v) => (v.takesAnOrder = false));
      const waiting = this.shifts.filter(
        (v) => v.status === this.$shift.statusEnum.WAITING
      );
      if (waiting.length) {
        const withoutOrders = waiting.find(
          (v) => !this.orders.find((el) => el.courier?.id === v.user.id)
        );
        if (withoutOrders) withoutOrders.takesAnOrder = true;
        else waiting[0].takesAnOrder = true;
      }
    },
    async loadOrders() {
      this.$order
        .sendGetSingle("for_couriers", undefined, this.filters)
        .then((v) => {
          v.result.forEach((order) => {
            this.setPackagedWaitingTime(order);
          });
          this.orders = v.result;
          for (const el of this.orders) {
            const index = this.selectedOrders.findIndex((v) => v.id === el.id);
            if (index > -1) this.selectedOrders[index] = el;
          }
          this.updateShiftsLocalStatus();
        });
    },
    async loadAll() {
      await this.loadOrders();
      await this.loadShifts();
    },
    async loadShifts() {
      this.$shift
        .sendGetSingle("current", undefined, this.filters)
        .then((v) => {
          let values = v.result;
          values = values.sort((a, b) =>
            a.status_updated_at > b.status_updated_at ? 1 : 0
          );
          if (this.selectedShift) {
            const found = values.find((v) => v.id === this.selectedShift.id);
            if (found) this.selectedShift = { ...found, takesAnOrder: true };
          }
          this.shifts = values;
          this.updateShiftsLocalStatus();
        });
    },
    setPackagedWaitingTime(order) {
      const now = moment.now();
      if (order.packaged_at) {
        const minutes = Math.floor(
          (now - moment.utc(order.packaged_at).local()) / 1000 / 60
        );
        order.packagedWaitingTime = minutes;
      } else order.packagedWaitingTime = 0;
    },
    deliveryOrdersInitScroll() {
      const scroller = this.$refs.ordersScroller;
      const children = scroller.firstChild.children;
      const index = this.orders.findIndex((v) => v.id === this.$order.item?.id);
      if (index < -1) return;
      let height = 0;
      for (let i = 0; i < index; i++) {
        height += children.item(i).clientHeight + 8;
      }
      scroller.scrollTo(0, height);
    },
    selectOrder(order) {
      const index = this.selectedOrders.findIndex((v) => v.id === order.id);
      if (index > -1) {
        this.selectedOrders.splice(index, 1);
        if (!this.selectedOrders.length && !this.takesAnOrderManual) {
          this.selectedShift = null;
        }
      } else {
        const shift = this.shifts.find((v) => v.takesAnOrder) || null;
        this.selectedShift = shift;
        this.selectedOrders.push(order);
      }
    },
    selectCourier(shift) {
      this.shifts.forEach((v) => (v.takesAnOrder = false));
      if (this.selectedShift && this.selectedShift.id === shift.id) {
        this.selectedShift = null;
        this.takesAnOrderManual = false;
        this.updateShiftsLocalStatus();
      } else {
        shift.takesAnOrder = true;
        this.selectedShift = shift;
        this.takesAnOrderManual = true;
      }
    },
    setOrdersToShift() {
      if (!this.selectedShift || !this.selectedOrders.length) return;
      const items = [];
      for (const order of this.selectedOrders) {
        items.push({ order: order.id, courier: this.selectedShift.user.id });
      }
      this.$order
        .sendPostSingle("set_couriers", undefined, { items })
        .then(() => {
          this.selectedOrders.forEach(
            (v) => (v.courier = this.selectedShift.user)
          );
          this.selectedShift = null;
          this.selectedOrders = [];
          this.$store.commit("setSnackbar", {
            color: "success",
            text: "Успешно",
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            color: "fail",
            text: "Ошибка",
          });
        });
    },
    setFiltersOrdersByShift(shift) {
      if (!this.filterOrdersByShift) {
        this.lastValueOfOnlyWithoutCourier = clone(this.onlyWithoutCourier);
        this.onlyWithoutCourier = false;
        this.filterOrdersByShift = shift;
      } else if (this.filterOrdersByShift.id === shift.id) {
        this.onlyWithoutCourier = clone(this.lastValueOfOnlyWithoutCourier);
        this.filterOrdersByShift = null;
      } else {
        this.lastValueOfOnlyWithoutCourier = clone(this.onlyWithoutCourier);
        this.onlyWithoutCourier = false;
        this.filterOrdersByShift = shift;
      }
    },
    setOutletCourierWs() {
      if (this.outletCourierWs) {
        this.tryReconnectOutletCourierWs = false;
        this.outletCourierWs.close();
      }
      const token = localStorage.getItem("access");
      const protocol = window.location.protocol === "https:" ? "wss" : "ws";
      const host = window.location.host.includes("127.0.0.1")
        ? "127.0.0.1:8100"
        : window.location.host;
      this.outletCourierWs = new WebSocket(
        `${protocol}://${host}/ws/outlet_courier/all/?token=${token}`
      );
      this.outletCourierWs.onmessage = this.outletCourierWsHandler;
      this.outletCourierWs.onclose = () => {
        if (
          !this.tryReconnectOutletCourierWs &&
          this.outletCourierWsReconnectInterval
        )
          clearInterval(this.outletCourierWsReconnectInterval);
        if (this.tryReconnectOutletCourierWs)
          this.outletCourierWsReconnectInterval = setInterval(() => {
            this.setOutletCourierWs();
          }, 5000);
      };
    },
    outletCourierWsHandler(res) {
      this.updateShiftsLocalStatus();
      const data = JSON.parse(res.data);
      switch (data.type) {
        case "orders.updated":
          return this.updateOrders(data.data);
        case "order.updated":
          return this.updateOrders([data.data]);
        case "shift.start":
          return this.updateShift(data.data, data.type);
        case "shift.end":
          return this.updateShift(data.data, data.type);
        case "shift.start_break":
          return this.updateShift(data.data, data.type);
        case "shift.start_waiting":
          return this.updateShift(data.data, data.type);
        case "shift.start_working":
          return this.updateShift(data.data, data.type);
        case "shift.start_going_back":
          return this.updateShift(data.data, data.type);
        case "courier_location.updated":
          return this.updateShiftByCoords(data.data);
        case "order_review.created":
          return this.onOrderReviewCreated(data.data);
      }
    },
    onOrderReviewCreated(review) {
      if (this.reviewsModal) {
        const found = this.orderReviews.find(v => v.id === review.id)
        if (!found) this.orderReviews.unshift(review)
      }
    },
    updateShiftByCoords(track) {
      const shift = this.shifts.find((v) => v.user.id === track.created_by);
      if (!shift) return;
      shift.last_position = track.coords;
      this.$refs.mapRef?.loadSelectedShiftTrack();
      this.shiftsRenderKey++;
    },
    updateOrders(orders) {
      for (const order of orders) {
        if (order.pickup_type !== this.$order.pickupTypeEnum.DELIVERY) continue;
        if (this.filters.outlet && order.outlet.id !== this.filters.outlet) {
          continue;
        }
        const index = this.orders.findIndex((v) => v.id === order.id);
        if (index < 0) {
          if (
            (!this.filters.outlet || this.filters.outlet === order.outlet.id) &&
            order.user_status !== this.$order.userStatusEnum.DECLINED
          ) {
            this.orders.push(order);
            return;
          }
        }
        Object.assign(this.orders[index], order);
      }
    },
    updateShift(shift, action) {
      if (shift.outlet.id !== this.filters.outlet && this.filters.outlet)
        return;
      const index = this.shifts.findIndex((v) => v.id === shift.id);
      if (index < 0 && action === "shift.start") {
        this.shifts.push(shift);
      } else if (index > -1 && action === "shift.end") {
        this.shifts.splice(index, 1);
      } else {
        if (index < 0) return;
        else {
          Object.assign(this.shifts[index], shift);
        }
      }
      if (this.selectedShift?.id === shift.id) this.selectedShift = shift;
    },
    parseDate(date) {
      return window.moment.localDateTime(date);
    },
  },
  computed: {
    orderReviews() {
      return orderReviews;
    },
    filteredOrders() {
      let res = this.orders;
      if (this.filterOrdersByShift)
        res = res.filter(
          (v) => v.courier?.id === this.filterOrdersByShift.user.id
        );
      if (this.onlyWithoutCourier) res = res.filter((v) => !v.courier);
      return res.sort((a, b) => this.sortOrders(a, b));
    },
  },
  mounted() {
    this.outletById = {};
    for (const el of this.$auth.user.outlets) {
      this.outletById[el.id] = el;
    }
    this.loadAll();
    this.reloadOrdersListInterval = setInterval(
      () => this.loadOrders(),
      30 * 1000
    );
    this.setOutletCourierWs();
  },
  beforeDestroy() {
    if (this.reloadOrdersListInterval)
      clearInterval(this.reloadOrdersListInterval);
    if (this.outletCourierWs) {
      this.tryReconnectOutletCourierWs = false;
      this.outletCourierWs.close();
    }
  },
  watch: {
    reviewsModal(v) {
      if (v) {
        this.$orderReview
          .loadList({
            order_pickup_type: this.$order.pickupTypeEnum.DELIVERY,
            start_date: moment()
              .subtract(18, "hours")
              .utc()
              .format("YYYY-DD-MM HH:mm:ss"),
            page_size: "all",
          })
          .then((v) => {
            this.orderReviews = v;
          });
      }
    },
  },
};
</script>

<style scoped></style>
